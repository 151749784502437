/**
 * Do not edit directly
 * Generated on Tue, 15 Nov 2022 14:19:42 GMT
 */

export default {
  "components": {
    "checkbox": {
      "backgroundColor": {
        "value": "transparent",
        "type": "color",
        "filePath": "./src/theme/resolvedTokens.json",
        "isSource": true,
        "original": {
          "value": "transparent",
          "type": "color"
        },
        "name": "componentsCheckboxBackgroundColor",
        "attributes": {},
        "path": [
          "components",
          "checkbox",
          "backgroundColor"
        ]
      }
    },
    "checkbox__circle": {
      "backgroundColor": {
        "value": "#9333ea",
        "type": "color",
        "filePath": "./src/theme/resolvedTokens.json",
        "isSource": true,
        "original": {
          "value": "#9333ea",
          "type": "color"
        },
        "name": "componentsCheckboxCircleBackgroundColor",
        "attributes": {},
        "path": [
          "components",
          "checkbox__circle",
          "backgroundColor"
        ]
      },
      "borderColor": {
        "value": "#9333ea",
        "type": "color",
        "filePath": "./src/theme/resolvedTokens.json",
        "isSource": true,
        "original": {
          "value": "#9333ea",
          "type": "color"
        },
        "name": "componentsCheckboxCircleBorderColor",
        "attributes": {},
        "path": [
          "components",
          "checkbox__circle",
          "borderColor"
        ]
      },
      "borderWidth": {
        "value": 2,
        "type": "borderWidth",
        "filePath": "./src/theme/resolvedTokens.json",
        "isSource": true,
        "original": {
          "value": 2,
          "type": "borderWidth"
        },
        "name": "componentsCheckboxCircleBorderWidth",
        "attributes": {},
        "path": [
          "components",
          "checkbox__circle",
          "borderWidth"
        ]
      },
      "borderRadius": {
        "value": 99999,
        "type": "borderRadius",
        "filePath": "./src/theme/resolvedTokens.json",
        "isSource": true,
        "original": {
          "value": 99999,
          "type": "borderRadius"
        },
        "name": "componentsCheckboxCircleBorderRadius",
        "attributes": {},
        "path": [
          "components",
          "checkbox__circle",
          "borderRadius"
        ]
      },
      "size": {
        "value": 32,
        "type": "sizing",
        "filePath": "./src/theme/resolvedTokens.json",
        "isSource": true,
        "original": {
          "value": 32,
          "type": "sizing"
        },
        "name": "componentsCheckboxCircleSize",
        "attributes": {},
        "path": [
          "components",
          "checkbox__circle",
          "size"
        ]
      }
    },
    "checkbox__icon": {
      "color": {
        "value": "#faf5ff",
        "type": "color",
        "filePath": "./src/theme/resolvedTokens.json",
        "isSource": true,
        "original": {
          "value": "#faf5ff",
          "type": "color"
        },
        "name": "componentsCheckboxIconColor",
        "attributes": {},
        "path": [
          "components",
          "checkbox__icon",
          "color"
        ]
      }
    },
    "card": {
      "backgroundColor": {
        "value": "#27272a",
        "type": "color",
        "filePath": "./src/theme/resolvedTokens.json",
        "isSource": true,
        "original": {
          "value": "#27272a",
          "type": "color"
        },
        "name": "componentsCardBackgroundColor",
        "attributes": {},
        "path": [
          "components",
          "card",
          "backgroundColor"
        ]
      },
      "color": {
        "value": "#faf5ff",
        "type": "color",
        "filePath": "./src/theme/resolvedTokens.json",
        "isSource": true,
        "original": {
          "value": "#faf5ff",
          "type": "color"
        },
        "name": "componentsCardColor",
        "attributes": {},
        "path": [
          "components",
          "card",
          "color"
        ]
      },
      "borderRadius": {
        "value": 8,
        "type": "borderRadius",
        "filePath": "./src/theme/resolvedTokens.json",
        "isSource": true,
        "original": {
          "value": 8,
          "type": "borderRadius"
        },
        "name": "componentsCardBorderRadius",
        "attributes": {},
        "path": [
          "components",
          "card",
          "borderRadius"
        ]
      }
    },
    "button": {
      "container": {
        "default": {
          "borderRadius": {
            "value": 8,
            "type": "borderRadius",
            "filePath": "./src/theme/resolvedTokens.json",
            "isSource": true,
            "original": {
              "value": 8,
              "type": "borderRadius"
            },
            "name": "componentsButtonContainerDefaultBorderRadius",
            "attributes": {},
            "path": [
              "components",
              "button",
              "container",
              "default",
              "borderRadius"
            ]
          },
          "borderWidth": {
            "value": 2,
            "type": "borderWidth",
            "filePath": "./src/theme/resolvedTokens.json",
            "isSource": true,
            "original": {
              "value": 2,
              "type": "borderWidth"
            },
            "name": "componentsButtonContainerDefaultBorderWidth",
            "attributes": {},
            "path": [
              "components",
              "button",
              "container",
              "default",
              "borderWidth"
            ]
          },
          "borderColor": {
            "value": "#27272a",
            "type": "color",
            "filePath": "./src/theme/resolvedTokens.json",
            "isSource": true,
            "original": {
              "value": "#27272a",
              "type": "color"
            },
            "name": "componentsButtonContainerDefaultBorderColor",
            "attributes": {},
            "path": [
              "components",
              "button",
              "container",
              "default",
              "borderColor"
            ]
          },
          "paddingHorizontal": {
            "value": 32,
            "type": "spacing",
            "filePath": "./src/theme/resolvedTokens.json",
            "isSource": true,
            "original": {
              "value": 32,
              "type": "spacing"
            },
            "name": "componentsButtonContainerDefaultPaddingHorizontal",
            "attributes": {},
            "path": [
              "components",
              "button",
              "container",
              "default",
              "paddingHorizontal"
            ]
          },
          "paddingVertical": {
            "value": 8,
            "type": "spacing",
            "filePath": "./src/theme/resolvedTokens.json",
            "isSource": true,
            "original": {
              "value": 8,
              "type": "spacing"
            },
            "name": "componentsButtonContainerDefaultPaddingVertical",
            "attributes": {},
            "path": [
              "components",
              "button",
              "container",
              "default",
              "paddingVertical"
            ]
          },
          "backgroundColor": {
            "value": "#27272a",
            "type": "color",
            "filePath": "./src/theme/resolvedTokens.json",
            "isSource": true,
            "original": {
              "value": "#27272a",
              "type": "color"
            },
            "name": "componentsButtonContainerDefaultBackgroundColor",
            "attributes": {},
            "path": [
              "components",
              "button",
              "container",
              "default",
              "backgroundColor"
            ]
          }
        },
        "primary": {
          "borderColor": {
            "value": "#9333ea",
            "type": "color",
            "filePath": "./src/theme/resolvedTokens.json",
            "isSource": true,
            "original": {
              "value": "#9333ea",
              "type": "color"
            },
            "name": "componentsButtonContainerPrimaryBorderColor",
            "attributes": {},
            "path": [
              "components",
              "button",
              "container",
              "primary",
              "borderColor"
            ]
          },
          "backgroundColor": {
            "value": "#9333ea",
            "type": "color",
            "filePath": "./src/theme/resolvedTokens.json",
            "isSource": true,
            "original": {
              "value": "#9333ea",
              "type": "color"
            },
            "name": "componentsButtonContainerPrimaryBackgroundColor",
            "attributes": {},
            "path": [
              "components",
              "button",
              "container",
              "primary",
              "backgroundColor"
            ]
          }
        },
        "secondary": {
          "borderColor": {
            "value": "#faf5ff",
            "type": "color",
            "filePath": "./src/theme/resolvedTokens.json",
            "isSource": true,
            "original": {
              "value": "#faf5ff",
              "type": "color"
            },
            "name": "componentsButtonContainerSecondaryBorderColor",
            "attributes": {},
            "path": [
              "components",
              "button",
              "container",
              "secondary",
              "borderColor"
            ]
          },
          "backgroundColor": {
            "value": "transparent",
            "type": "color",
            "filePath": "./src/theme/resolvedTokens.json",
            "isSource": true,
            "original": {
              "value": "transparent",
              "type": "color"
            },
            "name": "componentsButtonContainerSecondaryBackgroundColor",
            "attributes": {},
            "path": [
              "components",
              "button",
              "container",
              "secondary",
              "backgroundColor"
            ]
          }
        }
      },
      "title": {
        "default": {
          "color": {
            "value": "#faf5ff",
            "type": "color",
            "filePath": "./src/theme/resolvedTokens.json",
            "isSource": true,
            "original": {
              "value": "#faf5ff",
              "type": "color"
            },
            "name": "componentsButtonTitleDefaultColor",
            "attributes": {},
            "path": [
              "components",
              "button",
              "title",
              "default",
              "color"
            ]
          },
          "fontSize": {
            "value": 18,
            "type": "fontSizes",
            "filePath": "./src/theme/resolvedTokens.json",
            "isSource": true,
            "original": {
              "value": 18,
              "type": "fontSizes"
            },
            "name": "componentsButtonTitleDefaultFontSize",
            "attributes": {},
            "path": [
              "components",
              "button",
              "title",
              "default",
              "fontSize"
            ]
          },
          "fontWeight": {
            "value": 600,
            "type": "fontWeights",
            "filePath": "./src/theme/resolvedTokens.json",
            "isSource": true,
            "original": {
              "value": 600,
              "type": "fontWeights"
            },
            "name": "componentsButtonTitleDefaultFontWeight",
            "attributes": {},
            "path": [
              "components",
              "button",
              "title",
              "default",
              "fontWeight"
            ]
          }
        }
      }
    }
  },
  "semantic": {
    "colors": {
      "base": {
        "value": "#18181b",
        "type": "color",
        "filePath": "./src/theme/resolvedTokens.json",
        "isSource": true,
        "original": {
          "value": "#18181b",
          "type": "color"
        },
        "name": "semanticColorsBase",
        "attributes": {},
        "path": [
          "semantic",
          "colors",
          "base"
        ]
      },
      "base100": {
        "value": "#27272a",
        "type": "color",
        "filePath": "./src/theme/resolvedTokens.json",
        "isSource": true,
        "original": {
          "value": "#27272a",
          "type": "color"
        },
        "name": "semanticColorsBase100",
        "attributes": {},
        "path": [
          "semantic",
          "colors",
          "base100"
        ]
      },
      "baseContent": {
        "value": "#fafafa",
        "type": "color",
        "filePath": "./src/theme/resolvedTokens.json",
        "isSource": true,
        "original": {
          "value": "#fafafa",
          "type": "color"
        },
        "name": "semanticColorsBaseContent",
        "attributes": {},
        "path": [
          "semantic",
          "colors",
          "baseContent"
        ]
      },
      "primary": {
        "value": "#9333ea",
        "type": "color",
        "filePath": "./src/theme/resolvedTokens.json",
        "isSource": true,
        "original": {
          "value": "#9333ea",
          "type": "color"
        },
        "name": "semanticColorsPrimary",
        "attributes": {},
        "path": [
          "semantic",
          "colors",
          "primary"
        ]
      },
      "primaryContent": {
        "value": "#faf5ff",
        "type": "color",
        "filePath": "./src/theme/resolvedTokens.json",
        "isSource": true,
        "original": {
          "value": "#faf5ff",
          "type": "color"
        },
        "name": "semanticColorsPrimaryContent",
        "attributes": {},
        "path": [
          "semantic",
          "colors",
          "primaryContent"
        ]
      },
      "secondary": {
        "value": "#71717a",
        "type": "color",
        "filePath": "./src/theme/resolvedTokens.json",
        "isSource": true,
        "original": {
          "value": "#71717a",
          "type": "color"
        },
        "name": "semanticColorsSecondary",
        "attributes": {},
        "path": [
          "semantic",
          "colors",
          "secondary"
        ]
      },
      "secondaryContent": {
        "value": "#fafafa",
        "type": "color",
        "filePath": "./src/theme/resolvedTokens.json",
        "isSource": true,
        "original": {
          "value": "#fafafa",
          "type": "color"
        },
        "name": "semanticColorsSecondaryContent",
        "attributes": {},
        "path": [
          "semantic",
          "colors",
          "secondaryContent"
        ]
      },
      "success": {
        "value": "#22c55e",
        "type": "color",
        "filePath": "./src/theme/resolvedTokens.json",
        "isSource": true,
        "original": {
          "value": "#22c55e",
          "type": "color"
        },
        "name": "semanticColorsSuccess",
        "attributes": {},
        "path": [
          "semantic",
          "colors",
          "success"
        ]
      },
      "successContent": {
        "value": "#f0fdf4",
        "type": "color",
        "filePath": "./src/theme/resolvedTokens.json",
        "isSource": true,
        "original": {
          "value": "#f0fdf4",
          "type": "color"
        },
        "name": "semanticColorsSuccessContent",
        "attributes": {},
        "path": [
          "semantic",
          "colors",
          "successContent"
        ]
      },
      "warning": {
        "value": "#eab308",
        "type": "color",
        "filePath": "./src/theme/resolvedTokens.json",
        "isSource": true,
        "original": {
          "value": "#eab308",
          "type": "color"
        },
        "name": "semanticColorsWarning",
        "attributes": {},
        "path": [
          "semantic",
          "colors",
          "warning"
        ]
      },
      "warningContent": {
        "value": "#713f12",
        "type": "color",
        "filePath": "./src/theme/resolvedTokens.json",
        "isSource": true,
        "original": {
          "value": "#713f12",
          "type": "color"
        },
        "name": "semanticColorsWarningContent",
        "attributes": {},
        "path": [
          "semantic",
          "colors",
          "warningContent"
        ]
      },
      "danger": {
        "value": "#ef4444",
        "type": "color",
        "filePath": "./src/theme/resolvedTokens.json",
        "isSource": true,
        "original": {
          "value": "#ef4444",
          "type": "color"
        },
        "name": "semanticColorsDanger",
        "attributes": {},
        "path": [
          "semantic",
          "colors",
          "danger"
        ]
      },
      "dangerContent": {
        "value": "#fef2f2",
        "type": "color",
        "filePath": "./src/theme/resolvedTokens.json",
        "isSource": true,
        "original": {
          "value": "#fef2f2",
          "type": "color"
        },
        "name": "semanticColorsDangerContent",
        "attributes": {},
        "path": [
          "semantic",
          "colors",
          "dangerContent"
        ]
      },
      "info": {
        "value": "#3b82f6",
        "type": "color",
        "filePath": "./src/theme/resolvedTokens.json",
        "isSource": true,
        "original": {
          "value": "#3b82f6",
          "type": "color"
        },
        "name": "semanticColorsInfo",
        "attributes": {},
        "path": [
          "semantic",
          "colors",
          "info"
        ]
      },
      "infoContent": {
        "value": "#eff6ff",
        "type": "color",
        "filePath": "./src/theme/resolvedTokens.json",
        "isSource": true,
        "original": {
          "value": "#eff6ff",
          "type": "color"
        },
        "name": "semanticColorsInfoContent",
        "attributes": {},
        "path": [
          "semantic",
          "colors",
          "infoContent"
        ]
      }
    },
    "background": {
      "default": {
        "value": "#fff",
        "type": "color",
        "filePath": "./src/theme/resolvedTokens.json",
        "isSource": true,
        "original": {
          "value": "#fff",
          "type": "color"
        },
        "name": "semanticBackgroundDefault",
        "attributes": {},
        "path": [
          "semantic",
          "background",
          "default"
        ]
      },
      "weak": {
        "value": "#e2e8f0",
        "type": "color",
        "filePath": "./src/theme/resolvedTokens.json",
        "isSource": true,
        "original": {
          "value": "#e2e8f0",
          "type": "color"
        },
        "name": "semanticBackgroundWeak",
        "attributes": {},
        "path": [
          "semantic",
          "background",
          "weak"
        ]
      }
    },
    "text": {
      "value": "#0f172a",
      "type": "color",
      "filePath": "./src/theme/resolvedTokens.json",
      "isSource": true,
      "original": {
        "value": "#0f172a",
        "type": "color"
      },
      "name": "semanticText",
      "attributes": {},
      "path": [
        "semantic",
        "text"
      ]
    },
    "radii": {
      "default": {
        "value": 8,
        "type": "borderRadius",
        "filePath": "./src/theme/resolvedTokens.json",
        "isSource": true,
        "original": {
          "value": 8,
          "type": "borderRadius"
        },
        "name": "semanticRadiiDefault",
        "attributes": {},
        "path": [
          "semantic",
          "radii",
          "default"
        ]
      }
    },
    "borderWidth": {
      "hairline": {
        "value": 1,
        "type": "borderWidth",
        "filePath": "./src/theme/resolvedTokens.json",
        "isSource": true,
        "original": {
          "value": 1,
          "type": "borderWidth"
        },
        "name": "semanticBorderWidthHairline",
        "attributes": {},
        "path": [
          "semantic",
          "borderWidth",
          "hairline"
        ]
      },
      "thin": {
        "value": 2,
        "type": "borderWidth",
        "filePath": "./src/theme/resolvedTokens.json",
        "isSource": true,
        "original": {
          "value": 2,
          "type": "borderWidth"
        },
        "name": "semanticBorderWidthThin",
        "attributes": {},
        "path": [
          "semantic",
          "borderWidth",
          "thin"
        ]
      }
    }
  },
  "core": {
    "_config": {
      "sizes": {
        "baseValue": {
          "value": 2,
          "type": "other",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": 2,
            "type": "other"
          },
          "name": "coreConfigSizesBaseValue",
          "attributes": {},
          "path": [
            "core",
            "_config",
            "sizes",
            "baseValue"
          ]
        },
        "scaleFactor": {
          "value": 2,
          "type": "other",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": 2,
            "type": "other"
          },
          "name": "coreConfigSizesScaleFactor",
          "attributes": {},
          "path": [
            "core",
            "_config",
            "sizes",
            "scaleFactor"
          ]
        }
      },
      "space": {
        "baseValue": {
          "value": 2,
          "type": "other",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": 2,
            "type": "other"
          },
          "name": "coreConfigSpaceBaseValue",
          "attributes": {},
          "path": [
            "core",
            "_config",
            "space",
            "baseValue"
          ]
        },
        "scaleFactor": {
          "value": 2,
          "type": "other",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": 2,
            "type": "other"
          },
          "name": "coreConfigSpaceScaleFactor",
          "attributes": {},
          "path": [
            "core",
            "_config",
            "space",
            "scaleFactor"
          ]
        }
      },
      "radii": {
        "baseValue": {
          "value": 2,
          "type": "other",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": 2,
            "type": "other"
          },
          "name": "coreConfigRadiiBaseValue",
          "attributes": {},
          "path": [
            "core",
            "_config",
            "radii",
            "baseValue"
          ]
        },
        "scaleFactor": {
          "value": 2,
          "type": "other",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": 2,
            "type": "other"
          },
          "name": "coreConfigRadiiScaleFactor",
          "attributes": {},
          "path": [
            "core",
            "_config",
            "radii",
            "scaleFactor"
          ]
        }
      },
      "colors": {
        "defaultSaturation": {
          "value": 80,
          "type": "other",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": 80,
            "type": "other"
          },
          "name": "coreConfigColorsDefaultSaturation",
          "attributes": {},
          "path": [
            "core",
            "_config",
            "colors",
            "defaultSaturation"
          ]
        },
        "lightSaturation": {
          "value": 20,
          "type": "other",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": 20,
            "type": "other"
          },
          "name": "coreConfigColorsLightSaturation",
          "attributes": {},
          "path": [
            "core",
            "_config",
            "colors",
            "lightSaturation"
          ]
        },
        "brightSaturation": {
          "value": 100,
          "type": "other",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": 100,
            "type": "other"
          },
          "name": "coreConfigColorsBrightSaturation",
          "attributes": {},
          "path": [
            "core",
            "_config",
            "colors",
            "brightSaturation"
          ]
        },
        "redHue": {
          "value": 0,
          "type": "other",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": 0,
            "type": "other"
          },
          "name": "coreConfigColorsRedHue",
          "attributes": {},
          "path": [
            "core",
            "_config",
            "colors",
            "redHue"
          ]
        },
        "orangeHue": {
          "value": 30,
          "type": "other",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": 30,
            "type": "other"
          },
          "name": "coreConfigColorsOrangeHue",
          "attributes": {},
          "path": [
            "core",
            "_config",
            "colors",
            "orangeHue"
          ]
        },
        "yellowHue": {
          "value": 60,
          "type": "other",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": 60,
            "type": "other"
          },
          "name": "coreConfigColorsYellowHue",
          "attributes": {},
          "path": [
            "core",
            "_config",
            "colors",
            "yellowHue"
          ]
        },
        "greenHue": {
          "value": 120,
          "type": "other",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": 120,
            "type": "other"
          },
          "name": "coreConfigColorsGreenHue",
          "attributes": {},
          "path": [
            "core",
            "_config",
            "colors",
            "greenHue"
          ]
        },
        "cyanHue": {
          "value": 180,
          "type": "other",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": 180,
            "type": "other"
          },
          "name": "coreConfigColorsCyanHue",
          "attributes": {},
          "path": [
            "core",
            "_config",
            "colors",
            "cyanHue"
          ]
        },
        "blueHue": {
          "value": 220,
          "type": "other",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": 220,
            "type": "other"
          },
          "name": "coreConfigColorsBlueHue",
          "attributes": {},
          "path": [
            "core",
            "_config",
            "colors",
            "blueHue"
          ]
        },
        "purpleHue": {
          "value": 300,
          "type": "other",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": 300,
            "type": "other"
          },
          "name": "coreConfigColorsPurpleHue",
          "attributes": {},
          "path": [
            "core",
            "_config",
            "colors",
            "purpleHue"
          ]
        }
      }
    },
    "sizes": {
      "zero": {
        "value": 0,
        "type": "sizing",
        "filePath": "./src/theme/resolvedTokens.json",
        "isSource": true,
        "original": {
          "value": 0,
          "type": "sizing"
        },
        "name": "coreSizesZero",
        "attributes": {},
        "path": [
          "core",
          "sizes",
          "zero"
        ]
      },
      "xxs": {
        "value": 2,
        "type": "sizing",
        "filePath": "./src/theme/resolvedTokens.json",
        "isSource": true,
        "original": {
          "value": 2,
          "type": "sizing"
        },
        "name": "coreSizesXxs",
        "attributes": {},
        "path": [
          "core",
          "sizes",
          "xxs"
        ]
      },
      "xs": {
        "value": 4,
        "type": "sizing",
        "filePath": "./src/theme/resolvedTokens.json",
        "isSource": true,
        "original": {
          "value": 4,
          "type": "sizing"
        },
        "name": "coreSizesXs",
        "attributes": {},
        "path": [
          "core",
          "sizes",
          "xs"
        ]
      },
      "sm": {
        "value": 8,
        "type": "sizing",
        "filePath": "./src/theme/resolvedTokens.json",
        "isSource": true,
        "original": {
          "value": 8,
          "type": "sizing"
        },
        "name": "coreSizesSm",
        "attributes": {},
        "path": [
          "core",
          "sizes",
          "sm"
        ]
      },
      "md": {
        "value": 16,
        "type": "sizing",
        "filePath": "./src/theme/resolvedTokens.json",
        "isSource": true,
        "original": {
          "value": 16,
          "type": "sizing"
        },
        "name": "coreSizesMd",
        "attributes": {},
        "path": [
          "core",
          "sizes",
          "md"
        ]
      },
      "lg": {
        "value": 32,
        "type": "sizing",
        "filePath": "./src/theme/resolvedTokens.json",
        "isSource": true,
        "original": {
          "value": 32,
          "type": "sizing"
        },
        "name": "coreSizesLg",
        "attributes": {},
        "path": [
          "core",
          "sizes",
          "lg"
        ]
      },
      "xl": {
        "value": 64,
        "type": "sizing",
        "filePath": "./src/theme/resolvedTokens.json",
        "isSource": true,
        "original": {
          "value": 64,
          "type": "sizing"
        },
        "name": "coreSizesXl",
        "attributes": {},
        "path": [
          "core",
          "sizes",
          "xl"
        ]
      },
      "xxl": {
        "value": 128,
        "type": "sizing",
        "filePath": "./src/theme/resolvedTokens.json",
        "isSource": true,
        "original": {
          "value": 128,
          "type": "sizing"
        },
        "name": "coreSizesXxl",
        "attributes": {},
        "path": [
          "core",
          "sizes",
          "xxl"
        ]
      }
    },
    "space": {
      "zero": {
        "value": 0,
        "type": "spacing",
        "filePath": "./src/theme/resolvedTokens.json",
        "isSource": true,
        "original": {
          "value": 0,
          "type": "spacing"
        },
        "name": "coreSpaceZero",
        "attributes": {},
        "path": [
          "core",
          "space",
          "zero"
        ]
      },
      "xxs": {
        "value": 2,
        "type": "spacing",
        "filePath": "./src/theme/resolvedTokens.json",
        "isSource": true,
        "original": {
          "value": 2,
          "type": "spacing"
        },
        "name": "coreSpaceXxs",
        "attributes": {},
        "path": [
          "core",
          "space",
          "xxs"
        ]
      },
      "xs": {
        "value": 4,
        "type": "spacing",
        "filePath": "./src/theme/resolvedTokens.json",
        "isSource": true,
        "original": {
          "value": 4,
          "type": "spacing"
        },
        "name": "coreSpaceXs",
        "attributes": {},
        "path": [
          "core",
          "space",
          "xs"
        ]
      },
      "sm": {
        "value": 8,
        "type": "spacing",
        "filePath": "./src/theme/resolvedTokens.json",
        "isSource": true,
        "original": {
          "value": 8,
          "type": "spacing"
        },
        "name": "coreSpaceSm",
        "attributes": {},
        "path": [
          "core",
          "space",
          "sm"
        ]
      },
      "md": {
        "value": 16,
        "type": "spacing",
        "filePath": "./src/theme/resolvedTokens.json",
        "isSource": true,
        "original": {
          "value": 16,
          "type": "spacing"
        },
        "name": "coreSpaceMd",
        "attributes": {},
        "path": [
          "core",
          "space",
          "md"
        ]
      },
      "lg": {
        "value": 32,
        "type": "spacing",
        "filePath": "./src/theme/resolvedTokens.json",
        "isSource": true,
        "original": {
          "value": 32,
          "type": "spacing"
        },
        "name": "coreSpaceLg",
        "attributes": {},
        "path": [
          "core",
          "space",
          "lg"
        ]
      }
    },
    "radii": {
      "zero": {
        "value": 0,
        "type": "borderRadius",
        "filePath": "./src/theme/resolvedTokens.json",
        "isSource": true,
        "original": {
          "value": 0,
          "type": "borderRadius"
        },
        "name": "coreRadiiZero",
        "attributes": {},
        "path": [
          "core",
          "radii",
          "zero"
        ]
      },
      "xs": {
        "value": 2,
        "type": "borderRadius",
        "filePath": "./src/theme/resolvedTokens.json",
        "isSource": true,
        "original": {
          "value": 2,
          "type": "borderRadius"
        },
        "name": "coreRadiiXs",
        "attributes": {},
        "path": [
          "core",
          "radii",
          "xs"
        ]
      },
      "sm": {
        "value": 4,
        "type": "borderRadius",
        "filePath": "./src/theme/resolvedTokens.json",
        "isSource": true,
        "original": {
          "value": 4,
          "type": "borderRadius"
        },
        "name": "coreRadiiSm",
        "attributes": {},
        "path": [
          "core",
          "radii",
          "sm"
        ]
      },
      "md": {
        "value": 8,
        "type": "borderRadius",
        "filePath": "./src/theme/resolvedTokens.json",
        "isSource": true,
        "original": {
          "value": 8,
          "type": "borderRadius"
        },
        "name": "coreRadiiMd",
        "attributes": {},
        "path": [
          "core",
          "radii",
          "md"
        ]
      },
      "lg": {
        "value": 16,
        "type": "borderRadius",
        "filePath": "./src/theme/resolvedTokens.json",
        "isSource": true,
        "original": {
          "value": 16,
          "type": "borderRadius"
        },
        "name": "coreRadiiLg",
        "attributes": {},
        "path": [
          "core",
          "radii",
          "lg"
        ]
      },
      "xl": {
        "value": 32,
        "type": "borderRadius",
        "filePath": "./src/theme/resolvedTokens.json",
        "isSource": true,
        "original": {
          "value": 32,
          "type": "borderRadius"
        },
        "name": "coreRadiiXl",
        "attributes": {},
        "path": [
          "core",
          "radii",
          "xl"
        ]
      },
      "round": {
        "value": 99999,
        "type": "borderRadius",
        "filePath": "./src/theme/resolvedTokens.json",
        "isSource": true,
        "original": {
          "value": 99999,
          "type": "borderRadius"
        },
        "name": "coreRadiiRound",
        "attributes": {},
        "path": [
          "core",
          "radii",
          "round"
        ]
      }
    },
    "borderWidth": {
      "zero": {
        "value": 0,
        "type": "borderWidth",
        "filePath": "./src/theme/resolvedTokens.json",
        "isSource": true,
        "original": {
          "value": 0,
          "type": "borderWidth"
        },
        "name": "coreBorderWidthZero",
        "attributes": {},
        "path": [
          "core",
          "borderWidth",
          "zero"
        ]
      },
      "xs": {
        "value": 1,
        "type": "borderWidth",
        "filePath": "./src/theme/resolvedTokens.json",
        "isSource": true,
        "original": {
          "value": 1,
          "type": "borderWidth"
        },
        "name": "coreBorderWidthXs",
        "attributes": {},
        "path": [
          "core",
          "borderWidth",
          "xs"
        ]
      },
      "sm": {
        "value": 2,
        "type": "borderWidth",
        "filePath": "./src/theme/resolvedTokens.json",
        "isSource": true,
        "original": {
          "value": 2,
          "type": "borderWidth"
        },
        "name": "coreBorderWidthSm",
        "attributes": {},
        "path": [
          "core",
          "borderWidth",
          "sm"
        ]
      },
      "md": {
        "value": 4,
        "type": "borderWidth",
        "filePath": "./src/theme/resolvedTokens.json",
        "isSource": true,
        "original": {
          "value": 4,
          "type": "borderWidth"
        },
        "name": "coreBorderWidthMd",
        "attributes": {},
        "path": [
          "core",
          "borderWidth",
          "md"
        ]
      },
      "lg": {
        "value": 8,
        "type": "borderWidth",
        "filePath": "./src/theme/resolvedTokens.json",
        "isSource": true,
        "original": {
          "value": 8,
          "type": "borderWidth"
        },
        "name": "coreBorderWidthLg",
        "attributes": {},
        "path": [
          "core",
          "borderWidth",
          "lg"
        ]
      },
      "xl": {
        "value": 16,
        "type": "borderWidth",
        "filePath": "./src/theme/resolvedTokens.json",
        "isSource": true,
        "original": {
          "value": 16,
          "type": "borderWidth"
        },
        "name": "coreBorderWidthXl",
        "attributes": {},
        "path": [
          "core",
          "borderWidth",
          "xl"
        ]
      }
    },
    "colors": {
      "black": {
        "value": "#000",
        "type": "color",
        "filePath": "./src/theme/resolvedTokens.json",
        "isSource": true,
        "original": {
          "value": "#000",
          "type": "color"
        },
        "name": "coreColorsBlack",
        "attributes": {},
        "path": [
          "core",
          "colors",
          "black"
        ]
      },
      "white": {
        "value": "#fff",
        "type": "color",
        "filePath": "./src/theme/resolvedTokens.json",
        "isSource": true,
        "original": {
          "value": "#fff",
          "type": "color"
        },
        "name": "coreColorsWhite",
        "attributes": {},
        "path": [
          "core",
          "colors",
          "white"
        ]
      },
      "transparent": {
        "value": "transparent",
        "type": "color",
        "filePath": "./src/theme/resolvedTokens.json",
        "isSource": true,
        "original": {
          "value": "transparent",
          "type": "color"
        },
        "name": "coreColorsTransparent",
        "attributes": {},
        "path": [
          "core",
          "colors",
          "transparent"
        ]
      },
      "slate": {
        "50": {
          "value": "#f8fafc",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#f8fafc",
            "type": "color"
          },
          "name": "coreColorsSlate50",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "slate",
            "50"
          ]
        },
        "100": {
          "value": "#f1f5f9",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#f1f5f9",
            "type": "color"
          },
          "name": "coreColorsSlate100",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "slate",
            "100"
          ]
        },
        "200": {
          "value": "#e2e8f0",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#e2e8f0",
            "type": "color"
          },
          "name": "coreColorsSlate200",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "slate",
            "200"
          ]
        },
        "300": {
          "value": "#cbd5e1",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#cbd5e1",
            "type": "color"
          },
          "name": "coreColorsSlate300",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "slate",
            "300"
          ]
        },
        "400": {
          "value": "#94a3b8",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#94a3b8",
            "type": "color"
          },
          "name": "coreColorsSlate400",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "slate",
            "400"
          ]
        },
        "500": {
          "value": "#64748b",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#64748b",
            "type": "color"
          },
          "name": "coreColorsSlate500",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "slate",
            "500"
          ]
        },
        "600": {
          "value": "#475569",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#475569",
            "type": "color"
          },
          "name": "coreColorsSlate600",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "slate",
            "600"
          ]
        },
        "700": {
          "value": "#334155",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#334155",
            "type": "color"
          },
          "name": "coreColorsSlate700",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "slate",
            "700"
          ]
        },
        "800": {
          "value": "#1e293b",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#1e293b",
            "type": "color"
          },
          "name": "coreColorsSlate800",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "slate",
            "800"
          ]
        },
        "900": {
          "value": "#0f172a",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#0f172a",
            "type": "color"
          },
          "name": "coreColorsSlate900",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "slate",
            "900"
          ]
        }
      },
      "gray": {
        "50": {
          "value": "#f9fafb",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#f9fafb",
            "type": "color"
          },
          "name": "coreColorsGray50",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "gray",
            "50"
          ]
        },
        "100": {
          "value": "#f3f4f6",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#f3f4f6",
            "type": "color"
          },
          "name": "coreColorsGray100",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "gray",
            "100"
          ]
        },
        "200": {
          "value": "#e5e7eb",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#e5e7eb",
            "type": "color"
          },
          "name": "coreColorsGray200",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "gray",
            "200"
          ]
        },
        "300": {
          "value": "#d1d5db",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#d1d5db",
            "type": "color"
          },
          "name": "coreColorsGray300",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "gray",
            "300"
          ]
        },
        "400": {
          "value": "#9ca3af",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#9ca3af",
            "type": "color"
          },
          "name": "coreColorsGray400",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "gray",
            "400"
          ]
        },
        "500": {
          "value": "#6b7280",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#6b7280",
            "type": "color"
          },
          "name": "coreColorsGray500",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "gray",
            "500"
          ]
        },
        "600": {
          "value": "#4b5563",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#4b5563",
            "type": "color"
          },
          "name": "coreColorsGray600",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "gray",
            "600"
          ]
        },
        "700": {
          "value": "#374151",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#374151",
            "type": "color"
          },
          "name": "coreColorsGray700",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "gray",
            "700"
          ]
        },
        "800": {
          "value": "#1f2937",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#1f2937",
            "type": "color"
          },
          "name": "coreColorsGray800",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "gray",
            "800"
          ]
        },
        "900": {
          "value": "#111827",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#111827",
            "type": "color"
          },
          "name": "coreColorsGray900",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "gray",
            "900"
          ]
        }
      },
      "zinc": {
        "50": {
          "value": "#fafafa",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#fafafa",
            "type": "color"
          },
          "name": "coreColorsZinc50",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "zinc",
            "50"
          ]
        },
        "100": {
          "value": "#f4f4f5",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#f4f4f5",
            "type": "color"
          },
          "name": "coreColorsZinc100",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "zinc",
            "100"
          ]
        },
        "200": {
          "value": "#e4e4e7",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#e4e4e7",
            "type": "color"
          },
          "name": "coreColorsZinc200",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "zinc",
            "200"
          ]
        },
        "300": {
          "value": "#d4d4d8",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#d4d4d8",
            "type": "color"
          },
          "name": "coreColorsZinc300",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "zinc",
            "300"
          ]
        },
        "400": {
          "value": "#a1a1aa",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#a1a1aa",
            "type": "color"
          },
          "name": "coreColorsZinc400",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "zinc",
            "400"
          ]
        },
        "500": {
          "value": "#71717a",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#71717a",
            "type": "color"
          },
          "name": "coreColorsZinc500",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "zinc",
            "500"
          ]
        },
        "600": {
          "value": "#52525b",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#52525b",
            "type": "color"
          },
          "name": "coreColorsZinc600",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "zinc",
            "600"
          ]
        },
        "700": {
          "value": "#3f3f46",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#3f3f46",
            "type": "color"
          },
          "name": "coreColorsZinc700",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "zinc",
            "700"
          ]
        },
        "800": {
          "value": "#27272a",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#27272a",
            "type": "color"
          },
          "name": "coreColorsZinc800",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "zinc",
            "800"
          ]
        },
        "900": {
          "value": "#18181b",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#18181b",
            "type": "color"
          },
          "name": "coreColorsZinc900",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "zinc",
            "900"
          ]
        }
      },
      "neutral": {
        "50": {
          "value": "#fafafa",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#fafafa",
            "type": "color"
          },
          "name": "coreColorsNeutral50",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "neutral",
            "50"
          ]
        },
        "100": {
          "value": "#f5f5f5",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#f5f5f5",
            "type": "color"
          },
          "name": "coreColorsNeutral100",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "neutral",
            "100"
          ]
        },
        "200": {
          "value": "#e5e5e5",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#e5e5e5",
            "type": "color"
          },
          "name": "coreColorsNeutral200",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "neutral",
            "200"
          ]
        },
        "300": {
          "value": "#d4d4d4",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#d4d4d4",
            "type": "color"
          },
          "name": "coreColorsNeutral300",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "neutral",
            "300"
          ]
        },
        "400": {
          "value": "#a3a3a3",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#a3a3a3",
            "type": "color"
          },
          "name": "coreColorsNeutral400",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "neutral",
            "400"
          ]
        },
        "500": {
          "value": "#737373",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#737373",
            "type": "color"
          },
          "name": "coreColorsNeutral500",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "neutral",
            "500"
          ]
        },
        "600": {
          "value": "#525252",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#525252",
            "type": "color"
          },
          "name": "coreColorsNeutral600",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "neutral",
            "600"
          ]
        },
        "700": {
          "value": "#404040",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#404040",
            "type": "color"
          },
          "name": "coreColorsNeutral700",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "neutral",
            "700"
          ]
        },
        "800": {
          "value": "#262626",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#262626",
            "type": "color"
          },
          "name": "coreColorsNeutral800",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "neutral",
            "800"
          ]
        },
        "900": {
          "value": "#171717",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#171717",
            "type": "color"
          },
          "name": "coreColorsNeutral900",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "neutral",
            "900"
          ]
        }
      },
      "stone": {
        "50": {
          "value": "#fafaf9",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#fafaf9",
            "type": "color"
          },
          "name": "coreColorsStone50",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "stone",
            "50"
          ]
        },
        "100": {
          "value": "#f5f5f4",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#f5f5f4",
            "type": "color"
          },
          "name": "coreColorsStone100",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "stone",
            "100"
          ]
        },
        "200": {
          "value": "#e7e5e4",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#e7e5e4",
            "type": "color"
          },
          "name": "coreColorsStone200",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "stone",
            "200"
          ]
        },
        "300": {
          "value": "#d6d3d1",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#d6d3d1",
            "type": "color"
          },
          "name": "coreColorsStone300",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "stone",
            "300"
          ]
        },
        "400": {
          "value": "#a8a29e",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#a8a29e",
            "type": "color"
          },
          "name": "coreColorsStone400",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "stone",
            "400"
          ]
        },
        "500": {
          "value": "#78716c",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#78716c",
            "type": "color"
          },
          "name": "coreColorsStone500",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "stone",
            "500"
          ]
        },
        "600": {
          "value": "#57534e",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#57534e",
            "type": "color"
          },
          "name": "coreColorsStone600",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "stone",
            "600"
          ]
        },
        "700": {
          "value": "#44403c",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#44403c",
            "type": "color"
          },
          "name": "coreColorsStone700",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "stone",
            "700"
          ]
        },
        "800": {
          "value": "#292524",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#292524",
            "type": "color"
          },
          "name": "coreColorsStone800",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "stone",
            "800"
          ]
        },
        "900": {
          "value": "#1c1917",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#1c1917",
            "type": "color"
          },
          "name": "coreColorsStone900",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "stone",
            "900"
          ]
        }
      },
      "red": {
        "50": {
          "value": "#fef2f2",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#fef2f2",
            "type": "color"
          },
          "name": "coreColorsRed50",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "red",
            "50"
          ]
        },
        "100": {
          "value": "#fee2e2",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#fee2e2",
            "type": "color"
          },
          "name": "coreColorsRed100",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "red",
            "100"
          ]
        },
        "200": {
          "value": "#fecaca",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#fecaca",
            "type": "color"
          },
          "name": "coreColorsRed200",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "red",
            "200"
          ]
        },
        "300": {
          "value": "#fca5a5",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#fca5a5",
            "type": "color"
          },
          "name": "coreColorsRed300",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "red",
            "300"
          ]
        },
        "400": {
          "value": "#f87171",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#f87171",
            "type": "color"
          },
          "name": "coreColorsRed400",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "red",
            "400"
          ]
        },
        "500": {
          "value": "#ef4444",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#ef4444",
            "type": "color"
          },
          "name": "coreColorsRed500",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "red",
            "500"
          ]
        },
        "600": {
          "value": "#dc2626",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#dc2626",
            "type": "color"
          },
          "name": "coreColorsRed600",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "red",
            "600"
          ]
        },
        "700": {
          "value": "#b91c1c",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#b91c1c",
            "type": "color"
          },
          "name": "coreColorsRed700",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "red",
            "700"
          ]
        },
        "800": {
          "value": "#991b1b",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#991b1b",
            "type": "color"
          },
          "name": "coreColorsRed800",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "red",
            "800"
          ]
        },
        "900": {
          "value": "#7f1d1d",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#7f1d1d",
            "type": "color"
          },
          "name": "coreColorsRed900",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "red",
            "900"
          ]
        }
      },
      "orange": {
        "50": {
          "value": "#fff7ed",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#fff7ed",
            "type": "color"
          },
          "name": "coreColorsOrange50",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "orange",
            "50"
          ]
        },
        "100": {
          "value": "#ffedd5",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#ffedd5",
            "type": "color"
          },
          "name": "coreColorsOrange100",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "orange",
            "100"
          ]
        },
        "200": {
          "value": "#fed7aa",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#fed7aa",
            "type": "color"
          },
          "name": "coreColorsOrange200",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "orange",
            "200"
          ]
        },
        "300": {
          "value": "#fdba74",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#fdba74",
            "type": "color"
          },
          "name": "coreColorsOrange300",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "orange",
            "300"
          ]
        },
        "400": {
          "value": "#fb923c",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#fb923c",
            "type": "color"
          },
          "name": "coreColorsOrange400",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "orange",
            "400"
          ]
        },
        "500": {
          "value": "#f97316",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#f97316",
            "type": "color"
          },
          "name": "coreColorsOrange500",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "orange",
            "500"
          ]
        },
        "600": {
          "value": "#ea580c",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#ea580c",
            "type": "color"
          },
          "name": "coreColorsOrange600",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "orange",
            "600"
          ]
        },
        "700": {
          "value": "#c2410c",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#c2410c",
            "type": "color"
          },
          "name": "coreColorsOrange700",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "orange",
            "700"
          ]
        },
        "800": {
          "value": "#9a3412",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#9a3412",
            "type": "color"
          },
          "name": "coreColorsOrange800",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "orange",
            "800"
          ]
        },
        "900": {
          "value": "#7c2d12",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#7c2d12",
            "type": "color"
          },
          "name": "coreColorsOrange900",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "orange",
            "900"
          ]
        }
      },
      "amber": {
        "50": {
          "value": "#fffbeb",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#fffbeb",
            "type": "color"
          },
          "name": "coreColorsAmber50",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "amber",
            "50"
          ]
        },
        "100": {
          "value": "#fef3c7",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#fef3c7",
            "type": "color"
          },
          "name": "coreColorsAmber100",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "amber",
            "100"
          ]
        },
        "200": {
          "value": "#fde68a",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#fde68a",
            "type": "color"
          },
          "name": "coreColorsAmber200",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "amber",
            "200"
          ]
        },
        "300": {
          "value": "#fcd34d",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#fcd34d",
            "type": "color"
          },
          "name": "coreColorsAmber300",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "amber",
            "300"
          ]
        },
        "400": {
          "value": "#fbbf24",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#fbbf24",
            "type": "color"
          },
          "name": "coreColorsAmber400",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "amber",
            "400"
          ]
        },
        "500": {
          "value": "#f59e0b",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#f59e0b",
            "type": "color"
          },
          "name": "coreColorsAmber500",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "amber",
            "500"
          ]
        },
        "600": {
          "value": "#d97706",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#d97706",
            "type": "color"
          },
          "name": "coreColorsAmber600",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "amber",
            "600"
          ]
        },
        "700": {
          "value": "#b45309",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#b45309",
            "type": "color"
          },
          "name": "coreColorsAmber700",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "amber",
            "700"
          ]
        },
        "800": {
          "value": "#92400e",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#92400e",
            "type": "color"
          },
          "name": "coreColorsAmber800",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "amber",
            "800"
          ]
        },
        "900": {
          "value": "#78350f",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#78350f",
            "type": "color"
          },
          "name": "coreColorsAmber900",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "amber",
            "900"
          ]
        }
      },
      "yellow": {
        "50": {
          "value": "#fefce8",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#fefce8",
            "type": "color"
          },
          "name": "coreColorsYellow50",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "yellow",
            "50"
          ]
        },
        "100": {
          "value": "#fef9c3",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#fef9c3",
            "type": "color"
          },
          "name": "coreColorsYellow100",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "yellow",
            "100"
          ]
        },
        "200": {
          "value": "#fef08a",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#fef08a",
            "type": "color"
          },
          "name": "coreColorsYellow200",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "yellow",
            "200"
          ]
        },
        "300": {
          "value": "#fde047",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#fde047",
            "type": "color"
          },
          "name": "coreColorsYellow300",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "yellow",
            "300"
          ]
        },
        "400": {
          "value": "#facc15",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#facc15",
            "type": "color"
          },
          "name": "coreColorsYellow400",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "yellow",
            "400"
          ]
        },
        "500": {
          "value": "#eab308",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#eab308",
            "type": "color"
          },
          "name": "coreColorsYellow500",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "yellow",
            "500"
          ]
        },
        "600": {
          "value": "#ca8a04",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#ca8a04",
            "type": "color"
          },
          "name": "coreColorsYellow600",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "yellow",
            "600"
          ]
        },
        "700": {
          "value": "#a16207",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#a16207",
            "type": "color"
          },
          "name": "coreColorsYellow700",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "yellow",
            "700"
          ]
        },
        "800": {
          "value": "#854d0e",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#854d0e",
            "type": "color"
          },
          "name": "coreColorsYellow800",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "yellow",
            "800"
          ]
        },
        "900": {
          "value": "#713f12",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#713f12",
            "type": "color"
          },
          "name": "coreColorsYellow900",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "yellow",
            "900"
          ]
        }
      },
      "lime": {
        "50": {
          "value": "#f7fee7",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#f7fee7",
            "type": "color"
          },
          "name": "coreColorsLime50",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "lime",
            "50"
          ]
        },
        "100": {
          "value": "#ecfccb",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#ecfccb",
            "type": "color"
          },
          "name": "coreColorsLime100",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "lime",
            "100"
          ]
        },
        "200": {
          "value": "#d9f99d",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#d9f99d",
            "type": "color"
          },
          "name": "coreColorsLime200",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "lime",
            "200"
          ]
        },
        "300": {
          "value": "#bef264",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#bef264",
            "type": "color"
          },
          "name": "coreColorsLime300",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "lime",
            "300"
          ]
        },
        "400": {
          "value": "#a3e635",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#a3e635",
            "type": "color"
          },
          "name": "coreColorsLime400",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "lime",
            "400"
          ]
        },
        "500": {
          "value": "#84cc16",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#84cc16",
            "type": "color"
          },
          "name": "coreColorsLime500",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "lime",
            "500"
          ]
        },
        "600": {
          "value": "#65a30d",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#65a30d",
            "type": "color"
          },
          "name": "coreColorsLime600",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "lime",
            "600"
          ]
        },
        "700": {
          "value": "#4d7c0f",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#4d7c0f",
            "type": "color"
          },
          "name": "coreColorsLime700",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "lime",
            "700"
          ]
        },
        "800": {
          "value": "#3f6212",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#3f6212",
            "type": "color"
          },
          "name": "coreColorsLime800",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "lime",
            "800"
          ]
        },
        "900": {
          "value": "#365314",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#365314",
            "type": "color"
          },
          "name": "coreColorsLime900",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "lime",
            "900"
          ]
        }
      },
      "green": {
        "50": {
          "value": "#f0fdf4",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#f0fdf4",
            "type": "color"
          },
          "name": "coreColorsGreen50",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "green",
            "50"
          ]
        },
        "100": {
          "value": "#dcfce7",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#dcfce7",
            "type": "color"
          },
          "name": "coreColorsGreen100",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "green",
            "100"
          ]
        },
        "200": {
          "value": "#bbf7d0",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#bbf7d0",
            "type": "color"
          },
          "name": "coreColorsGreen200",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "green",
            "200"
          ]
        },
        "300": {
          "value": "#86efac",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#86efac",
            "type": "color"
          },
          "name": "coreColorsGreen300",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "green",
            "300"
          ]
        },
        "400": {
          "value": "#4ade80",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#4ade80",
            "type": "color"
          },
          "name": "coreColorsGreen400",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "green",
            "400"
          ]
        },
        "500": {
          "value": "#22c55e",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#22c55e",
            "type": "color"
          },
          "name": "coreColorsGreen500",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "green",
            "500"
          ]
        },
        "600": {
          "value": "#16a34a",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#16a34a",
            "type": "color"
          },
          "name": "coreColorsGreen600",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "green",
            "600"
          ]
        },
        "700": {
          "value": "#15803d",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#15803d",
            "type": "color"
          },
          "name": "coreColorsGreen700",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "green",
            "700"
          ]
        },
        "800": {
          "value": "#166534",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#166534",
            "type": "color"
          },
          "name": "coreColorsGreen800",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "green",
            "800"
          ]
        },
        "900": {
          "value": "#14532d",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#14532d",
            "type": "color"
          },
          "name": "coreColorsGreen900",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "green",
            "900"
          ]
        }
      },
      "emerald": {
        "50": {
          "value": "#ecfdf5",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#ecfdf5",
            "type": "color"
          },
          "name": "coreColorsEmerald50",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "emerald",
            "50"
          ]
        },
        "100": {
          "value": "#d1fae5",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#d1fae5",
            "type": "color"
          },
          "name": "coreColorsEmerald100",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "emerald",
            "100"
          ]
        },
        "200": {
          "value": "#a7f3d0",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#a7f3d0",
            "type": "color"
          },
          "name": "coreColorsEmerald200",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "emerald",
            "200"
          ]
        },
        "300": {
          "value": "#6ee7b7",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#6ee7b7",
            "type": "color"
          },
          "name": "coreColorsEmerald300",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "emerald",
            "300"
          ]
        },
        "400": {
          "value": "#34d399",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#34d399",
            "type": "color"
          },
          "name": "coreColorsEmerald400",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "emerald",
            "400"
          ]
        },
        "500": {
          "value": "#10b981",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#10b981",
            "type": "color"
          },
          "name": "coreColorsEmerald500",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "emerald",
            "500"
          ]
        },
        "600": {
          "value": "#059669",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#059669",
            "type": "color"
          },
          "name": "coreColorsEmerald600",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "emerald",
            "600"
          ]
        },
        "700": {
          "value": "#047857",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#047857",
            "type": "color"
          },
          "name": "coreColorsEmerald700",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "emerald",
            "700"
          ]
        },
        "800": {
          "value": "#065f46",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#065f46",
            "type": "color"
          },
          "name": "coreColorsEmerald800",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "emerald",
            "800"
          ]
        },
        "900": {
          "value": "#064e3b",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#064e3b",
            "type": "color"
          },
          "name": "coreColorsEmerald900",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "emerald",
            "900"
          ]
        }
      },
      "teal": {
        "50": {
          "value": "#f0fdfa",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#f0fdfa",
            "type": "color"
          },
          "name": "coreColorsTeal50",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "teal",
            "50"
          ]
        },
        "100": {
          "value": "#ccfbf1",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#ccfbf1",
            "type": "color"
          },
          "name": "coreColorsTeal100",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "teal",
            "100"
          ]
        },
        "200": {
          "value": "#99f6e4",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#99f6e4",
            "type": "color"
          },
          "name": "coreColorsTeal200",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "teal",
            "200"
          ]
        },
        "300": {
          "value": "#5eead4",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#5eead4",
            "type": "color"
          },
          "name": "coreColorsTeal300",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "teal",
            "300"
          ]
        },
        "400": {
          "value": "#2dd4bf",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#2dd4bf",
            "type": "color"
          },
          "name": "coreColorsTeal400",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "teal",
            "400"
          ]
        },
        "500": {
          "value": "#14b8a6",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#14b8a6",
            "type": "color"
          },
          "name": "coreColorsTeal500",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "teal",
            "500"
          ]
        },
        "600": {
          "value": "#0d9488",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#0d9488",
            "type": "color"
          },
          "name": "coreColorsTeal600",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "teal",
            "600"
          ]
        },
        "700": {
          "value": "#0f766e",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#0f766e",
            "type": "color"
          },
          "name": "coreColorsTeal700",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "teal",
            "700"
          ]
        },
        "800": {
          "value": "#115e59",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#115e59",
            "type": "color"
          },
          "name": "coreColorsTeal800",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "teal",
            "800"
          ]
        },
        "900": {
          "value": "#134e4a",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#134e4a",
            "type": "color"
          },
          "name": "coreColorsTeal900",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "teal",
            "900"
          ]
        }
      },
      "cyan": {
        "50": {
          "value": "#ecfeff",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#ecfeff",
            "type": "color"
          },
          "name": "coreColorsCyan50",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "cyan",
            "50"
          ]
        },
        "100": {
          "value": "#cffafe",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#cffafe",
            "type": "color"
          },
          "name": "coreColorsCyan100",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "cyan",
            "100"
          ]
        },
        "200": {
          "value": "#a5f3fc",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#a5f3fc",
            "type": "color"
          },
          "name": "coreColorsCyan200",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "cyan",
            "200"
          ]
        },
        "300": {
          "value": "#67e8f9",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#67e8f9",
            "type": "color"
          },
          "name": "coreColorsCyan300",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "cyan",
            "300"
          ]
        },
        "400": {
          "value": "#22d3ee",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#22d3ee",
            "type": "color"
          },
          "name": "coreColorsCyan400",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "cyan",
            "400"
          ]
        },
        "500": {
          "value": "#06b6d4",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#06b6d4",
            "type": "color"
          },
          "name": "coreColorsCyan500",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "cyan",
            "500"
          ]
        },
        "600": {
          "value": "#0891b2",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#0891b2",
            "type": "color"
          },
          "name": "coreColorsCyan600",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "cyan",
            "600"
          ]
        },
        "700": {
          "value": "#0e7490",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#0e7490",
            "type": "color"
          },
          "name": "coreColorsCyan700",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "cyan",
            "700"
          ]
        },
        "800": {
          "value": "#155e75",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#155e75",
            "type": "color"
          },
          "name": "coreColorsCyan800",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "cyan",
            "800"
          ]
        },
        "900": {
          "value": "#164e63",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#164e63",
            "type": "color"
          },
          "name": "coreColorsCyan900",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "cyan",
            "900"
          ]
        }
      },
      "sky": {
        "50": {
          "value": "#f0f9ff",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#f0f9ff",
            "type": "color"
          },
          "name": "coreColorsSky50",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "sky",
            "50"
          ]
        },
        "100": {
          "value": "#e0f2fe",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#e0f2fe",
            "type": "color"
          },
          "name": "coreColorsSky100",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "sky",
            "100"
          ]
        },
        "200": {
          "value": "#bae6fd",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#bae6fd",
            "type": "color"
          },
          "name": "coreColorsSky200",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "sky",
            "200"
          ]
        },
        "300": {
          "value": "#7dd3fc",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#7dd3fc",
            "type": "color"
          },
          "name": "coreColorsSky300",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "sky",
            "300"
          ]
        },
        "400": {
          "value": "#38bdf8",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#38bdf8",
            "type": "color"
          },
          "name": "coreColorsSky400",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "sky",
            "400"
          ]
        },
        "500": {
          "value": "#0ea5e9",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#0ea5e9",
            "type": "color"
          },
          "name": "coreColorsSky500",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "sky",
            "500"
          ]
        },
        "600": {
          "value": "#0284c7",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#0284c7",
            "type": "color"
          },
          "name": "coreColorsSky600",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "sky",
            "600"
          ]
        },
        "700": {
          "value": "#0369a1",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#0369a1",
            "type": "color"
          },
          "name": "coreColorsSky700",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "sky",
            "700"
          ]
        },
        "800": {
          "value": "#075985",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#075985",
            "type": "color"
          },
          "name": "coreColorsSky800",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "sky",
            "800"
          ]
        },
        "900": {
          "value": "#0c4a6e",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#0c4a6e",
            "type": "color"
          },
          "name": "coreColorsSky900",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "sky",
            "900"
          ]
        }
      },
      "blue": {
        "50": {
          "value": "#eff6ff",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#eff6ff",
            "type": "color"
          },
          "name": "coreColorsBlue50",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "blue",
            "50"
          ]
        },
        "100": {
          "value": "#dbeafe",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#dbeafe",
            "type": "color"
          },
          "name": "coreColorsBlue100",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "blue",
            "100"
          ]
        },
        "200": {
          "value": "#bfdbfe",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#bfdbfe",
            "type": "color"
          },
          "name": "coreColorsBlue200",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "blue",
            "200"
          ]
        },
        "300": {
          "value": "#93c5fd",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#93c5fd",
            "type": "color"
          },
          "name": "coreColorsBlue300",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "blue",
            "300"
          ]
        },
        "400": {
          "value": "#60a5fa",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#60a5fa",
            "type": "color"
          },
          "name": "coreColorsBlue400",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "blue",
            "400"
          ]
        },
        "500": {
          "value": "#3b82f6",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#3b82f6",
            "type": "color"
          },
          "name": "coreColorsBlue500",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "blue",
            "500"
          ]
        },
        "600": {
          "value": "#2563eb",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#2563eb",
            "type": "color"
          },
          "name": "coreColorsBlue600",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "blue",
            "600"
          ]
        },
        "700": {
          "value": "#1d4ed8",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#1d4ed8",
            "type": "color"
          },
          "name": "coreColorsBlue700",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "blue",
            "700"
          ]
        },
        "800": {
          "value": "#1e40af",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#1e40af",
            "type": "color"
          },
          "name": "coreColorsBlue800",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "blue",
            "800"
          ]
        },
        "900": {
          "value": "#1e3a8a",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#1e3a8a",
            "type": "color"
          },
          "name": "coreColorsBlue900",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "blue",
            "900"
          ]
        }
      },
      "indigo": {
        "50": {
          "value": "#eef2ff",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#eef2ff",
            "type": "color"
          },
          "name": "coreColorsIndigo50",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "indigo",
            "50"
          ]
        },
        "100": {
          "value": "#e0e7ff",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#e0e7ff",
            "type": "color"
          },
          "name": "coreColorsIndigo100",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "indigo",
            "100"
          ]
        },
        "200": {
          "value": "#c7d2fe",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#c7d2fe",
            "type": "color"
          },
          "name": "coreColorsIndigo200",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "indigo",
            "200"
          ]
        },
        "300": {
          "value": "#a5b4fc",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#a5b4fc",
            "type": "color"
          },
          "name": "coreColorsIndigo300",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "indigo",
            "300"
          ]
        },
        "400": {
          "value": "#818cf8",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#818cf8",
            "type": "color"
          },
          "name": "coreColorsIndigo400",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "indigo",
            "400"
          ]
        },
        "500": {
          "value": "#6366f1",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#6366f1",
            "type": "color"
          },
          "name": "coreColorsIndigo500",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "indigo",
            "500"
          ]
        },
        "600": {
          "value": "#4f46e5",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#4f46e5",
            "type": "color"
          },
          "name": "coreColorsIndigo600",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "indigo",
            "600"
          ]
        },
        "700": {
          "value": "#4338ca",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#4338ca",
            "type": "color"
          },
          "name": "coreColorsIndigo700",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "indigo",
            "700"
          ]
        },
        "800": {
          "value": "#3730a3",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#3730a3",
            "type": "color"
          },
          "name": "coreColorsIndigo800",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "indigo",
            "800"
          ]
        },
        "900": {
          "value": "#312e81",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#312e81",
            "type": "color"
          },
          "name": "coreColorsIndigo900",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "indigo",
            "900"
          ]
        }
      },
      "violet": {
        "50": {
          "value": "#f5f3ff",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#f5f3ff",
            "type": "color"
          },
          "name": "coreColorsViolet50",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "violet",
            "50"
          ]
        },
        "100": {
          "value": "#ede9fe",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#ede9fe",
            "type": "color"
          },
          "name": "coreColorsViolet100",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "violet",
            "100"
          ]
        },
        "200": {
          "value": "#ddd6fe",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#ddd6fe",
            "type": "color"
          },
          "name": "coreColorsViolet200",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "violet",
            "200"
          ]
        },
        "300": {
          "value": "#c4b5fd",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#c4b5fd",
            "type": "color"
          },
          "name": "coreColorsViolet300",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "violet",
            "300"
          ]
        },
        "400": {
          "value": "#a78bfa",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#a78bfa",
            "type": "color"
          },
          "name": "coreColorsViolet400",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "violet",
            "400"
          ]
        },
        "500": {
          "value": "#8b5cf6",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#8b5cf6",
            "type": "color"
          },
          "name": "coreColorsViolet500",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "violet",
            "500"
          ]
        },
        "600": {
          "value": "#7c3aed",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#7c3aed",
            "type": "color"
          },
          "name": "coreColorsViolet600",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "violet",
            "600"
          ]
        },
        "700": {
          "value": "#6d28d9",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#6d28d9",
            "type": "color"
          },
          "name": "coreColorsViolet700",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "violet",
            "700"
          ]
        },
        "800": {
          "value": "#5b21b6",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#5b21b6",
            "type": "color"
          },
          "name": "coreColorsViolet800",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "violet",
            "800"
          ]
        },
        "900": {
          "value": "#4c1d95",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#4c1d95",
            "type": "color"
          },
          "name": "coreColorsViolet900",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "violet",
            "900"
          ]
        }
      },
      "purple": {
        "50": {
          "value": "#faf5ff",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#faf5ff",
            "type": "color"
          },
          "name": "coreColorsPurple50",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "purple",
            "50"
          ]
        },
        "100": {
          "value": "#f3e8ff",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#f3e8ff",
            "type": "color"
          },
          "name": "coreColorsPurple100",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "purple",
            "100"
          ]
        },
        "200": {
          "value": "#e9d5ff",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#e9d5ff",
            "type": "color"
          },
          "name": "coreColorsPurple200",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "purple",
            "200"
          ]
        },
        "300": {
          "value": "#d8b4fe",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#d8b4fe",
            "type": "color"
          },
          "name": "coreColorsPurple300",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "purple",
            "300"
          ]
        },
        "400": {
          "value": "#c084fc",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#c084fc",
            "type": "color"
          },
          "name": "coreColorsPurple400",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "purple",
            "400"
          ]
        },
        "500": {
          "value": "#a855f7",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#a855f7",
            "type": "color"
          },
          "name": "coreColorsPurple500",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "purple",
            "500"
          ]
        },
        "600": {
          "value": "#9333ea",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#9333ea",
            "type": "color"
          },
          "name": "coreColorsPurple600",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "purple",
            "600"
          ]
        },
        "700": {
          "value": "#7e22ce",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#7e22ce",
            "type": "color"
          },
          "name": "coreColorsPurple700",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "purple",
            "700"
          ]
        },
        "800": {
          "value": "#6b21a8",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#6b21a8",
            "type": "color"
          },
          "name": "coreColorsPurple800",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "purple",
            "800"
          ]
        },
        "900": {
          "value": "#581c87",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#581c87",
            "type": "color"
          },
          "name": "coreColorsPurple900",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "purple",
            "900"
          ]
        }
      },
      "fuchsia": {
        "50": {
          "value": "#fdf4ff",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#fdf4ff",
            "type": "color"
          },
          "name": "coreColorsFuchsia50",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "fuchsia",
            "50"
          ]
        },
        "100": {
          "value": "#fae8ff",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#fae8ff",
            "type": "color"
          },
          "name": "coreColorsFuchsia100",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "fuchsia",
            "100"
          ]
        },
        "200": {
          "value": "#f5d0fe",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#f5d0fe",
            "type": "color"
          },
          "name": "coreColorsFuchsia200",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "fuchsia",
            "200"
          ]
        },
        "300": {
          "value": "#f0abfc",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#f0abfc",
            "type": "color"
          },
          "name": "coreColorsFuchsia300",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "fuchsia",
            "300"
          ]
        },
        "400": {
          "value": "#e879f9",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#e879f9",
            "type": "color"
          },
          "name": "coreColorsFuchsia400",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "fuchsia",
            "400"
          ]
        },
        "500": {
          "value": "#d946ef",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#d946ef",
            "type": "color"
          },
          "name": "coreColorsFuchsia500",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "fuchsia",
            "500"
          ]
        },
        "600": {
          "value": "#c026d3",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#c026d3",
            "type": "color"
          },
          "name": "coreColorsFuchsia600",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "fuchsia",
            "600"
          ]
        },
        "700": {
          "value": "#a21caf",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#a21caf",
            "type": "color"
          },
          "name": "coreColorsFuchsia700",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "fuchsia",
            "700"
          ]
        },
        "800": {
          "value": "#86198f",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#86198f",
            "type": "color"
          },
          "name": "coreColorsFuchsia800",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "fuchsia",
            "800"
          ]
        },
        "900": {
          "value": "#701a75",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#701a75",
            "type": "color"
          },
          "name": "coreColorsFuchsia900",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "fuchsia",
            "900"
          ]
        }
      },
      "pink": {
        "50": {
          "value": "#fdf2f8",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#fdf2f8",
            "type": "color"
          },
          "name": "coreColorsPink50",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "pink",
            "50"
          ]
        },
        "100": {
          "value": "#fce7f3",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#fce7f3",
            "type": "color"
          },
          "name": "coreColorsPink100",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "pink",
            "100"
          ]
        },
        "200": {
          "value": "#fbcfe8",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#fbcfe8",
            "type": "color"
          },
          "name": "coreColorsPink200",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "pink",
            "200"
          ]
        },
        "300": {
          "value": "#f9a8d4",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#f9a8d4",
            "type": "color"
          },
          "name": "coreColorsPink300",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "pink",
            "300"
          ]
        },
        "400": {
          "value": "#f472b6",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#f472b6",
            "type": "color"
          },
          "name": "coreColorsPink400",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "pink",
            "400"
          ]
        },
        "500": {
          "value": "#ec4899",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#ec4899",
            "type": "color"
          },
          "name": "coreColorsPink500",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "pink",
            "500"
          ]
        },
        "600": {
          "value": "#db2777",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#db2777",
            "type": "color"
          },
          "name": "coreColorsPink600",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "pink",
            "600"
          ]
        },
        "700": {
          "value": "#be185d",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#be185d",
            "type": "color"
          },
          "name": "coreColorsPink700",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "pink",
            "700"
          ]
        },
        "800": {
          "value": "#9d174d",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#9d174d",
            "type": "color"
          },
          "name": "coreColorsPink800",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "pink",
            "800"
          ]
        },
        "900": {
          "value": "#831843",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#831843",
            "type": "color"
          },
          "name": "coreColorsPink900",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "pink",
            "900"
          ]
        }
      },
      "rose": {
        "50": {
          "value": "#fff1f2",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#fff1f2",
            "type": "color"
          },
          "name": "coreColorsRose50",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "rose",
            "50"
          ]
        },
        "100": {
          "value": "#ffe4e6",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#ffe4e6",
            "type": "color"
          },
          "name": "coreColorsRose100",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "rose",
            "100"
          ]
        },
        "200": {
          "value": "#fecdd3",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#fecdd3",
            "type": "color"
          },
          "name": "coreColorsRose200",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "rose",
            "200"
          ]
        },
        "300": {
          "value": "#fda4af",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#fda4af",
            "type": "color"
          },
          "name": "coreColorsRose300",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "rose",
            "300"
          ]
        },
        "400": {
          "value": "#fb7185",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#fb7185",
            "type": "color"
          },
          "name": "coreColorsRose400",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "rose",
            "400"
          ]
        },
        "500": {
          "value": "#f43f5e",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#f43f5e",
            "type": "color"
          },
          "name": "coreColorsRose500",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "rose",
            "500"
          ]
        },
        "600": {
          "value": "#e11d48",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#e11d48",
            "type": "color"
          },
          "name": "coreColorsRose600",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "rose",
            "600"
          ]
        },
        "700": {
          "value": "#be123c",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#be123c",
            "type": "color"
          },
          "name": "coreColorsRose700",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "rose",
            "700"
          ]
        },
        "800": {
          "value": "#9f1239",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#9f1239",
            "type": "color"
          },
          "name": "coreColorsRose800",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "rose",
            "800"
          ]
        },
        "900": {
          "value": "#881337",
          "type": "color",
          "filePath": "./src/theme/resolvedTokens.json",
          "isSource": true,
          "original": {
            "value": "#881337",
            "type": "color"
          },
          "name": "coreColorsRose900",
          "attributes": {},
          "path": [
            "core",
            "colors",
            "rose",
            "900"
          ]
        }
      }
    },
    "fontSize": {
      "lg": {
        "value": 16,
        "type": "fontSizes",
        "filePath": "./src/theme/resolvedTokens.json",
        "isSource": true,
        "original": {
          "value": 16,
          "type": "fontSizes"
        },
        "name": "coreFontSizeLg",
        "attributes": {},
        "path": [
          "core",
          "fontSize",
          "lg"
        ]
      },
      "xl": {
        "value": 18,
        "type": "fontSizes",
        "filePath": "./src/theme/resolvedTokens.json",
        "isSource": true,
        "original": {
          "value": 18,
          "type": "fontSizes"
        },
        "name": "coreFontSizeXl",
        "attributes": {},
        "path": [
          "core",
          "fontSize",
          "xl"
        ]
      }
    },
    "fontWeight": {
      "bold": {
        "value": 600,
        "type": "fontWeights",
        "filePath": "./src/theme/resolvedTokens.json",
        "isSource": true,
        "original": {
          "value": 600,
          "type": "fontWeights"
        },
        "name": "coreFontWeightBold",
        "attributes": {},
        "path": [
          "core",
          "fontWeight",
          "bold"
        ]
      }
    }
  }
};