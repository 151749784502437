import { useEffect } from 'react';
import Animated, {
  Easing,
  interpolate,
  SharedValue,
  useAnimatedProps,
  useDerivedValue,
  useSharedValue,
  withRepeat,
  withSpring,
  withTiming,
} from 'react-native-reanimated';
import { polar2Canvas } from 'react-native-redash';
import {
  Circle,
  ClipPath,
  Defs,
  G,
  Line,
  Path,
  RadialGradient,
  Rect,
  Stop,
  Svg,
} from 'react-native-svg';
import { useTheme } from '../../../hooks/useTheme';

const AnimatedPath = Animated.createAnimatedComponent(Path);
const AnimatedRect = Animated.createAnimatedComponent(Rect);
Animated.addWhitelistedNativeProps({ transform: true, r: true });

const INITIAL_ANGLE_OFFSET = Math.PI / 2;

type ClockFaceProps = {
  size: number;
  padding?: number;
  angle: SharedValue<number>;
  color?: string;
  isRunning?: boolean;
};
export const ClockFace = ({
  size,
  padding,
  angle,
  color,
  isRunning,
}: ClockFaceProps) => {
  const halfSize = size / 2;
  const radius = halfSize - (padding ?? 0);

  const springyAngle = useDerivedValue(() =>
    withSpring(angle.value, {
      damping: 10,
      stiffness: 100,
      overshootClamping: true,
    })
  );

  return (
    <Svg width={size} height={size} viewBox={`0 0 ${size} ${size}`}>
      <Defs>
        <FillRadialGradient />
        <FillClipPath size={radius * 2} angle={springyAngle} color={color} />
      </Defs>
      <G transform={`translate(${padding} ${padding})`}>
        <Fill size={size} isRunning={isRunning} />
        {/* {isRunning && <Pulse size={radius * 2} />} */}
        <Hand size={radius * 2} angle={springyAngle} isRunning={isRunning} />
        <Graduations size={radius * 2} />
      </G>
    </Svg>
  );
};

const AnimatedCircle = Animated.createAnimatedComponent(Circle);

type PulseProps = {
  size: number;
};
const Pulse = ({ size }: PulseProps) => {
  const progress = useSharedValue(0);

  useEffect(() => {
    progress.value = withRepeat(
      withTiming(1, {
        duration: 5000,
        easing: Easing.out(Easing.ease),
      }),
      -1
    );
  }, []);

  const animatedProps = useAnimatedProps(() => {
    return {
      r: (progress.value * size) / 2,
      opacity: interpolate(progress.value, [0, 1], [0.25, 0]),
    };
  }, [size]);

  return (
    <AnimatedCircle
      animatedProps={animatedProps}
      cx={size / 2}
      cy={size / 2}
      fill="url(#fill-radial-gradient)"
      clipPath="url(#fill-clip-path)"
      onPress={() => {}}
    />
  );
};

type FillClipPathProps = {
  size: number;
  angle: SharedValue<number>;
  color?: string;
};
const FillClipPath = ({ size, angle, color }: FillClipPathProps) => {
  const halfSize = size / 2;
  const radius = halfSize;

  const animatedProps = useAnimatedProps(() => {
    let theta = (-angle.value * Math.PI) / 180;
    theta += theta === -2 * Math.PI ? 0.000001 : 0;
    theta += INITIAL_ANGLE_OFFSET;

    const { x, y } = polar2Canvas(
      { theta: theta, radius: radius },
      { x: halfSize, y: halfSize }
    );
    return {
      d: `M ${halfSize} ${halfSize} L ${halfSize} ${
        halfSize - radius
      } A ${radius} ${radius} 0 ${
        Math.abs(theta) >= Math.PI / 2 ? 1 : 0
      } 1 ${x} ${y} Z`,
    };
  });

  return (
    <ClipPath id="fill-clip-path">
      <AnimatedPath animatedProps={animatedProps} onPress={() => {}} />
    </ClipPath>
  );
};

const FillRadialGradient = () => {
  const {
    semantic: { colors },
  } = useTheme();
  return (
    <RadialGradient id="fill-radial-gradient">
      <Stop offset="0%" stopColor="white" stopOpacity={0} />
      <Stop offset="100%" stopColor={colors.primaryContent} />
    </RadialGradient>
  );
};

type FillProps = {
  size: number;
  isRunning?: boolean;
};
const Fill = ({ size, isRunning }: FillProps) => {
  const {
    semantic: { colors },
  } = useTheme();

  const animatedProps = useAnimatedProps(() => {
    return {
      opacity: withSpring(isRunning ? 1 / 2 : 1, { overshootClamping: true }),
    };
  });

  return (
    <AnimatedRect
      x={0}
      y={0}
      width={size}
      height={size}
      fill={colors.primary}
      clipPath="url(#fill-clip-path)"
      animatedProps={animatedProps}
      onPress={() => {}}
    />
  );
};

type HandProps = {
  size: number;
  angle: SharedValue<number>;
  isRunning: boolean;
};
const Hand = ({ size, angle, isRunning }: HandProps) => {
  const handStrokeWidth = size / 128;
  const halfSize = size / 2;
  const radius = halfSize;

  const animatedHandProps = useAnimatedProps(() => {
    let theta = (-angle.value * Math.PI) / 180;
    theta += INITIAL_ANGLE_OFFSET;
    const { x, y } = polar2Canvas(
      { theta: theta, radius: radius },
      { x: halfSize, y: halfSize }
    );
    return {
      d: `M ${halfSize} ${halfSize} L ${x} ${y}`,
    };
  });

  const cirlceMinSize = size / 64;
  const circleMaxSize = size / 48;

  const animatedCircleProps = useAnimatedProps(() => {
    return {
      r: isRunning
        ? withRepeat(withTiming(circleMaxSize, { duration: 500 }), -1, true)
        : withSpring(cirlceMinSize),
    };
  }, [size, isRunning]);

  return (
    <>
      <AnimatedCircle
        cx={halfSize}
        cy={halfSize}
        r={size / 64}
        fill="#fff"
        animatedProps={animatedCircleProps}
        onPress={() => {}}
      />
      <AnimatedPath
        stroke="white"
        strokeWidth={handStrokeWidth}
        strokeLinecap="round"
        animatedProps={animatedHandProps}
        onPress={() => {}}
      />
    </>
  );
};

type GraduationsProps = {
  size: number;
};
const Graduations = ({ size }: GraduationsProps) => {
  const width = size / 128;
  const halfSize = size / 2;
  const height = width * 4;

  return (
    <>
      {Array.from({ length: 60 }).map((_, index) => {
        const angle = (index * 360) / 60;
        const isBig = index % 5 === 0;
        return (
          <Line
            key={index}
            x1={halfSize}
            y1={2}
            x2={halfSize}
            y2={isBig ? 2 * height : height}
            stroke="white"
            strokeWidth={width}
            strokeLinecap="round"
            opacity={0.5}
            transform={`rotate(${angle}, ${halfSize}, ${halfSize})`}
          />
        );
      })}
    </>
  );
};
