import { useState } from 'react';
import {
  runOnJS,
  useAnimatedReaction,
  useDerivedValue,
  useSharedValue,
} from 'react-native-reanimated';

const secondsToAngle = (seconds: number) => {
  'worklet';
  return (seconds / 60) * 6;
};

const angleToSeconds = (angle: number) => {
  'worklet';
  return (angle / 6) * 60;
};

type UseTimerAnimationProps = {
  isRunning?: boolean;
  seconds: number;
  onSecondsChange: (seconds: number) => void;
};
export const useTimerAnimation = ({
  isRunning,
  seconds,
  onSecondsChange,
}: UseTimerAnimationProps) => {
  const [hasDuration, setHasDuration] = useState(false);
  const angle = useSharedValue(seconds);

  useAnimatedReaction(
    () => angle.value > 0,
    (result, previous) => result !== previous && runOnJS(setHasDuration)(result)
  );

  useAnimatedReaction(
    () => angle.value,
    (result, previous) =>
      !isRunning &&
      result !== previous &&
      runOnJS(onSecondsChange)(angleToSeconds(result)),
    [isRunning]
  );

  useAnimatedReaction(
    () => null,
    () => {
      if (isRunning || seconds === 0) {
        angle.value = secondsToAngle(seconds);
      }
    },
    [seconds, isRunning]
  );

  return {
    angle,
    hasDuration,
  };
};
