export enum TestId {
  InboxButton = 'inbox-button',
  TaskListItem = 'task-list-item',
  NewTaskButton = 'new-task-button',
  AddTaskButton = 'add-task-button',
  NewTaskInput = 'new-task-input',
  TaskNameInput = 'task-name-input',
  TaskDetailName = 'task-detail-name',

  TimerClockFace = 'timer-clock-face',
  TimerDurationDisplay = 'timer-duration-display',
  TimerEndTimeDisplay = 'timer-end-time-display',
  TimerButtons = 'timer-buttons',
  TimerStartButton = 'timer-start-button',
  TimerCancelButton = 'timer-cancel-button',

  InputButton = 'input-button',
  InputButtonLabel = 'input-button-label',
  InputButtonInput = 'input-button-input',
  InputButtonValue = 'input-button-value',

  HabitItem = 'habit-item',
  HabitItemTitle = 'habit-item-title',
  HabitItemCompletion = 'habit-item-completion',

  HabitItemDayButton = 'habit-item-day-button',
  HabitItemDayCompleted = 'habit-item-day-completed',
  HabitItemDayNotCompleted = 'habit-item-day-not-completed',
}

export const testId = (id: TestId) => ({ testID: id });

export const subId = (id: string, variant: string) => `${id}-${variant}`;
