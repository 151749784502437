import { View, Text } from 'dripsy';
import { TestId, testId } from '../../../constants/testIds';
import { TasksStackScreenProps } from '../../../types';

export const TaskDetailScreen = ({
  route,
}: TasksStackScreenProps<'TaskDetail'>) => {
  const { id } = route.params;

  const task = {
    title: 'Task 1',
  };

  return (
    <View>
      <View {...testId(TestId.TaskDetailName)}>
        <Text sx={{ color: 'text' }} {...testId(TestId.TaskNameInput)}>
          {task.title}
        </Text>
      </View>
    </View>
  );
};
