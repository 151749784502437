import { StatusBar } from 'expo-status-bar';
import { Providers } from './src/components/Providers';

import useCachedResources from './src/hooks/useCachedResources';
import useColorScheme from './src/hooks/useColorScheme';
import Navigation from './src/navigation';

import 'react-native-gesture-handler';
import 'react-native-get-random-values';

export default function App() {
  const isLoadingComplete = useCachedResources();
  const colorScheme = useColorScheme();

  if (!isLoadingComplete) {
    return null;
  } else {
    return (
      <Providers>
        <Navigation colorScheme={colorScheme} />
        <StatusBar />
      </Providers>
    );
  }
}
