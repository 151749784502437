const TIME_REGEX = /(\d{1,2})(.*)(\d{2})/g;
const HOURS_GROUP_INDEX = 1;
const MINUTES_GROUP_INDEX = 3;

export const textStringToDate = (text: string) => {
  const match = TIME_REGEX.exec(text);
  if (!match) return null;

  const hours = parseInt(match[HOURS_GROUP_INDEX], 10);
  const minutes = parseInt(match[MINUTES_GROUP_INDEX], 10);

  const date = new Date();
  date.setHours(hours);
  date.setMinutes(minutes);

  return date;
};
