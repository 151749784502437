import tokens from '../../theme/tokens';
import { ThemeTokens } from '../../theme/types';

const BLACKLISTED_COLOR_NAMES = ['black', 'white', 'transparent'];

export const createTheme = () => {
  const theme = mapTokensToTheme(tokens) as ThemeTokens;

  const filteredTheme = {
    ...theme,
    core: {
      ...theme.core,
      colors: {
        ...Object.keys(theme.core.colors).reduce((acc, colorName) => {
          if (!BLACKLISTED_COLOR_NAMES.includes(colorName)) {
            acc[colorName] = theme.core.colors[colorName];
          }
          return acc;
        }, {}),
      },
    },
  };

  return filteredTheme as ThemeTokens;
};

export const mapTokensToTheme = (state: any, key?: string) => {
  const property = typeof key === 'undefined' ? state : state[key];

  // If the property has a value, return it
  if ('value' in property) {
    if (property.type === 'fontWeights') {
      return property.value.toString();
    }

    return property.value;
  }

  return Object.keys(property).reduce((acc, curr) => {
    return { ...acc, [curr]: mapTokensToTheme(property, curr) };
  }, {});
};
