import { useState } from 'react';
import {
  View,
  Text,
  TextInput,
  StyleSheet,
  TouchableOpacity,
  TextStyle,
  TextInputProps,
} from 'react-native';
import { subId, TestId } from '../../../constants/testIds';
import { useTheme } from '../../../hooks/useTheme';
import { ThemeTokens } from '../../../theme/types';

export type InputButtonProps = {
  label: string;
  disabled?: boolean;
} & Pick<TextInputProps, 'value' | 'onChangeText' | 'keyboardType' | 'testID'>;
export const InputButton = ({
  label,
  value,
  disabled,
  keyboardType,
  onChangeText,
  testID = TestId.InputButton,
}: InputButtonProps) => {
  const { styles } = useTheme(styleFn);

  const [isEditing, setIsEditing] = useState(false);

  const handlePress = () => {
    setIsEditing(true);
  };

  const handleBlur = () => {
    setIsEditing(false);
  };

  return (
    <TouchableOpacity
      onPress={handlePress}
      disabled={disabled}
      style={[styles.container, isEditing && styles.containerActive]}
    >
      <Text
        style={[styles.text, styles.label, isEditing && styles.labelActive]}
        testID={subId(testID, 'label')}
      >
        {label}
      </Text>
      {isEditing ? (
        <TextInput
          onChangeText={onChangeText}
          onBlur={handleBlur}
          editable={!disabled}
          keyboardType={keyboardType}
          autoFocus
          style={[styles.text, styles.value, styles.input]}
          testID={subId(testID, 'input')}
          returnKeyType="done"
        />
      ) : (
        <Text
          style={[styles.text, styles.value]}
          testID={subId(testID, 'value')}
        >
          {value}
        </Text>
      )}
    </TouchableOpacity>
  );
};

const styleFn = ({ core, semantic }: ThemeTokens) =>
  StyleSheet.create({
    container: {
      backgroundColor: semantic.colors.base100,
      paddingVertical: core.space.sm,
      paddingHorizontal: core.space.md,
      borderRadius: core.radii.md,
    },
    containerActive: {
      backgroundColor: `${semantic.colors.primary}20`,
    },
    text: {
      fontSize: 16,
      color: semantic.colors.baseContent,
      fontWeight: 'bold',
    },
    label: {
      textTransform: 'uppercase',
      marginBottom: core.space.sm,
    },
    labelActive: {
      color: semantic.colors.primary,
    },
    value: {
      fontSize: 40,
      textAlign: 'center',
      fontVariant: ['tabular-nums'],
    },
    input: {
      outlineStyle: 'none',
    } as TextStyle,
  });
