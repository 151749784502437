import React, { ReactNode } from 'react';
import { Text, TextStyle, TouchableOpacity, View } from 'react-native';
import { useTheme } from '../hooks/useTheme';

export type ButtonVariant = 'default' | 'primary' | 'secondary';

export type ButtonProps = {
  title: ReactNode;
  variant?: ButtonVariant;
  onPress?: () => void;
  titleStyle?: TextStyle;
  testID?: string;
};

export const Button = ({
  title,
  titleStyle,
  variant = 'default',
  onPress,
  testID,
}: ButtonProps) => {
  const {
    components: { button },
  } = useTheme();

  return (
    <View>
      <TouchableOpacity
        style={[button.container.default, button.container[variant]]}
        onPress={onPress}
        testID={testID}
      >
        {typeof title === 'string' ? (
          <Text
            style={[
              { textAlign: 'center' },
              button.title.default,
              button.title[variant],
              titleStyle,
            ]}
          >
            {title}
          </Text>
        ) : (
          title
        )}
      </TouchableOpacity>
    </View>
  );
};
