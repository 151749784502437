import { addHours } from 'date-fns';
import * as Haptics from 'expo-haptics';
import React, { useEffect, useMemo } from 'react';
import { Platform, StyleSheet, Text, View } from 'react-native';
import Animated, { FadeInDown, FadeOutDown } from 'react-native-reanimated';
import { Button } from '../../../components/Button';
import { TestId, testId } from '../../../constants/testIds';
import {
  registerForPushNotificationsAsync,
  schedulePushNotification,
} from '../../../services/notifications/push';
import { RootTabScreenProps } from '../../../types';
import { ClockFace } from '../components/ClockFace';
import { ClockGesture } from '../components/ClockGesture';
import { DurationInputButton } from '../components/DurationInputButton';
import { EndTimeInputButton } from '../components/EndTimeInputButton';
import { useMaxWidth } from '../hooks/useMaxWidth';
import { TimerState, useTimer } from '../hooks/useTimer';
import { useTimerAnimation } from '../hooks/useTimerAnimation';
import { textStringToDate } from '../utils';

const handleTimerStart = ({ endTime }: TimerState) => {
  const endDate = new Date(endTime);
  void schedulePushNotification(endDate);
};

export const TimerScreen = () => {
  const width = useMaxWidth();
  const {
    durationInSeconds,
    endTime,
    isRunning,
    start,
    pause,
    reset,
    setDurationInSeconds,
  } = useTimer({
    onStart: handleTimerStart,
  });
  const { angle, hasDuration } = useTimerAnimation({
    isRunning,
    seconds: durationInSeconds,
    onSecondsChange: setDurationInSeconds,
  });

  const estimatedEndTime = useMemo(
    () => (isRunning ? endTime : Date.now() + durationInSeconds * 1000),
    [isRunning, endTime, durationInSeconds]
  );

  useEffect(() => {
    if (Platform.OS === 'web') return;

    void registerForPushNotificationsAsync();
  }, []);

  const handleSnap = () =>
    Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Light);

  const handleDurationInputChangeText = (text: string) => {
    const minutes = parseInt(text, 10);
    if (isNaN(minutes)) return;

    setDurationInSeconds(minutes * 60);
    angle.value = minutes * 6;
  };

  const handleEndTimeChangeText = (text: string) => {
    let dateFromText = textStringToDate(text);
    if (!dateFromText) return;

    const difference = dateFromText.getTime() - Date.now();
    const endDate = difference < 0 ? addHours(dateFromText, 12) : dateFromText;
    const adjustedDifference = endDate.getTime() - Date.now();
    const adjustedEndDate =
      adjustedDifference < 0 ? addHours(endDate, 12) : endDate;

    const seconds = Math.round((adjustedEndDate.getTime() - Date.now()) / 1000);
    const secondsCappedAt60Minutes = Math.min(seconds, 60 * 60);

    setDurationInSeconds(secondsCappedAt60Minutes);
    angle.value = secondsCappedAt60Minutes / 10;
  };

  return (
    <View style={styles.container}>
      <View style={[styles.innerContainer, { maxWidth: width }]}>
        <View style={[styles.display]}>
          <View style={styles.leftButton}>
            <DurationInputButton
              duration={durationInSeconds}
              disabled={isRunning}
              onChangeText={handleDurationInputChangeText}
            />
          </View>
          <View style={styles.rightButton}>
            <EndTimeInputButton
              endTime={estimatedEndTime}
              disabled={isRunning}
              onChangeText={handleEndTimeChangeText}
            />
          </View>
        </View>
        <ClockGesture
          angle={angle}
          size={width}
          snapToAngle={isRunning ? undefined : 6}
          enabled={!isRunning}
          onSnap={handleSnap}
        >
          <ClockFace
            angle={angle}
            size={width}
            isRunning={isRunning}
            padding={24}
            color={isRunning ? 'yellow' : undefined}
          />
        </ClockGesture>
        <View style={styles.footer}>
          {hasDuration && (
            <View
              style={styles.buttonContainer}
              {...testId(TestId.TimerButtons)}
            >
              <Animated.View
                style={styles.leftButton}
                entering={FadeInDown}
                exiting={FadeOutDown}
              >
                <Button
                  title="Cancel"
                  onPress={reset}
                  {...testId(TestId.TimerCancelButton)}
                />
              </Animated.View>

              <Animated.View
                style={styles.rightButton}
                entering={FadeInDown}
                exiting={FadeOutDown}
              >
                {isRunning ? (
                  <Button variant="primary" title="Pause" onPress={pause} />
                ) : (
                  <Button
                    variant="primary"
                    title="Start"
                    onPress={start}
                    {...testId(TestId.TimerStartButton)}
                  />
                )}
              </Animated.View>
            </View>
          )}
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    flex: 1,
    paddingBottom: 24,
  },
  innerContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
    color: '#bffdba',
    textAlign: 'center',
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: '80%',
  },
  display: {
    width: '100%',
    paddingHorizontal: 16,
    flexDirection: 'row',
  },
  buttonContainer: {
    width: '100%',
    flexDirection: 'row',
    paddingHorizontal: 16,
  },
  footer: { height: 64, width: '100%' },
  leftButton: {
    flex: 1,
    marginRight: 8,
  },
  rightButton: {
    flex: 1,
    marginLeft: 8,
  },
});
