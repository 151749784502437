import React from 'react';
import { useAddTask } from '../hooks/useAddTask';
import { NewTaskInputArea } from './NewTaskButton';

export const NewTaskSection = () => {
  const addTask = useAddTask();

  const handleAddTask = (text: string) => addTask(text);

  return <NewTaskInputArea onAddTask={handleAddTask} />;
};
