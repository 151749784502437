import AsyncStorage from '@react-native-async-storage/async-storage';
import { nanoid } from 'nanoid';
import { DataStore, Task } from '../types';

type EntitiesState<T> = {
  entities: {
    [key: string]: T;
  };
  ids: string[];
};

const setEntities = async <T>(
  entityKey: string,
  entities: EntitiesState<T>
) => {
  const entitiesJson = JSON.stringify(entities);
  await AsyncStorage.setItem(entityKey, entitiesJson);
};

const getEntities = async <T>(entityKey: string): Promise<EntitiesState<T>> => {
  const entitiesJson = await AsyncStorage.getItem(entityKey);
  return entitiesJson ? JSON.parse(entitiesJson) : { entities: {}, ids: [] };
};

export const AsyncStorageDataStore: DataStore = {
  getInboxTasks: async () => {
    const entities = await getEntities<Task>('tasks');

    return entities.ids.map((id) => entities.entities[id]);
  },

  addTask: async (title) => {
    const existingTasks = await getEntities<Task>('tasks');

    const newTaskId = nanoid();
    const newTasks = {
      entities: {
        ...existingTasks.entities,
        [newTaskId]: { id: newTaskId, title },
      },
      ids: [...existingTasks.ids, newTaskId],
    };

    setEntities('tasks', newTasks);
  },
};
