import { MaterialCommunityIcons } from '@expo/vector-icons';
import { ComponentProps } from 'react';

import { styled } from 'dripsy';

export type IconProps = ComponentProps<typeof MaterialCommunityIcons>;
const _Icon = (props: IconProps) => {
  return <MaterialCommunityIcons {...props} />;
};

export const Icon = styled(_Icon)();
