import React from 'react';
import { TestId, testId } from '../../../constants/testIds';
import { TouchableOpacity } from 'react-native';
import { View, Text } from 'dripsy';

type TaskListItemProps = {
  id: string;
  title: string;
  onPress: () => void;
};
export const TaskListItem = ({ title, onPress }: TaskListItemProps) => {
  return (
    <TouchableOpacity onPress={onPress} {...testId(TestId.TaskListItem)}>
      <View
        sx={{
          paddingX: '3',
          paddingY: '3',
          backgroundColor: 'card',
          borderRadius: 3,
        }}
      >
        <Text sx={{ color: 'text', fontSize: 3 }}>{title}</Text>
      </View>
    </TouchableOpacity>
  );
};
