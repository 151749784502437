/**
 * Learn more about deep linking with React Navigation
 * https://reactnavigation.org/docs/deep-linking
 * https://reactnavigation.org/docs/configuring-links
 */

import { LinkingOptions, getPathFromState } from '@react-navigation/native';
import * as Linking from 'expo-linking';

import { RootStackParamList } from '../types';

const linking: LinkingOptions<RootStackParamList> = {
  prefixes: [Linking.createURL('/')],
  config: {
    screens: {
      Root: {
        screens: {
          TasksRoot: {
            screens: {
              TasksHome: 'tasks',
              TasksInbox: 'tasks/inbox',
              TaskDetail: 'tasks/detail/:id',
            },
          },
          Timer: 'timer',
          HabitsRoot: {
            screens: {
              HabitsHome: 'habits',
            },
          },
        },
      },
      Modal: 'modal',
      NotFound: '*',
    },
  },
};

export default linking;
