import React, { PropsWithChildren } from 'react';
import { DripsyProvider, makeTheme } from 'dripsy';
import { SafeAreaProvider } from 'react-native-safe-area-context';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import useColorScheme from '../hooks/useColorScheme';
import { darkTheme, lightTheme } from '../constants/theme';

const queryClient = new QueryClient();

export const Providers = ({ children }: PropsWithChildren<unknown>) => {
  const colorScheme = useColorScheme();
  const theme = colorScheme === 'dark' ? darkTheme : lightTheme;

  return (
    <QueryClientProvider client={queryClient}>
      <SafeAreaProvider>
        <DripsyProvider theme={theme}>{children}</DripsyProvider>
      </SafeAreaProvider>
    </QueryClientProvider>
  );
};
