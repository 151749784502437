import { useNavigation } from '@react-navigation/native';
import { Text, StyleSheet } from 'react-native';
import { TestId, testId } from '../../../constants/testIds';
import { RootTabScreenProps, TasksStackParamList } from '../../../types';
import {
  createNativeStackNavigator,
  NativeStackHeaderProps,
} from '@react-navigation/native-stack';
import { TasksHomeScreen } from './TasksHomeScreen';
import TasksInboxScreen from './TasksInboxScreen';
import { TaskDetailScreen } from './TaskDetailScreen';

const TasksStack = createNativeStackNavigator<TasksStackParamList>();

export default function TasksRootScreen({}: RootTabScreenProps<'TasksRoot'>) {
  return (
    <TasksStack.Navigator
      screenOptions={{
        headerTintColor: 'white',
        headerLargeTitle: true,
      }}
    >
      <TasksStack.Screen
        name="TasksHome"
        component={TasksHomeScreen}
        options={{ title: 'Tasks' }}
      />
      <TasksStack.Screen
        name="TasksInbox"
        component={TasksInboxScreen}
        options={{ title: 'Inbox' }}
      />
      <TasksStack.Screen name="TaskDetail" component={TaskDetailScreen} />
    </TasksStack.Navigator>
  );
}
