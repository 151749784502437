import React from 'react';
import { View, Text } from 'dripsy';
import { useInboxTasks } from '../hooks/useInboxTasks';
import { TaskList } from './TaskList';
import { useNavigation } from '@react-navigation/native';
import { TasksStackScreenProps } from '../../../types';

export const TasksInboxList = () => {
  const navigation =
    useNavigation<TasksStackScreenProps<'TasksInbox'>['navigation']>();
  const { data: tasks } = useInboxTasks();

  const handleTaskPress = (id: string) => {
    navigation.navigate('TaskDetail', { id });
  };

  if (tasks) return <TaskList tasks={tasks} onTaskPress={handleTaskPress} />;

  return <TasksListSkeleton />;
};

const TasksListSkeleton = () => {
  return (
    <View sx={{ flex: 1 }}>
      <Text>Loading...</Text>
    </View>
  );
};
