import React, { useState } from 'react';
import { TouchableOpacity } from 'react-native';
import { View, Text, TextInput, H3 } from 'dripsy';
import { TestId, testId } from '../../../constants/testIds';
import { Icon } from './Icon';

const HEIGHT = 5;
const RADII = 4;

type NewTaskInputAreaProps = {
  onAddTask: (title: string) => void;
};
export const NewTaskInputArea = ({ onAddTask }: NewTaskInputAreaProps) => {
  const [isAddingTask, setIsAddingTask] = useState(false);
  const [newTaskTitle, setNewTaskTitle] = useState('');

  const handleChangeText = (text: string) => setNewTaskTitle(text);
  const handleNewTaskPress = () => setIsAddingTask(true);
  const handleAddTaskPress = () => {
    if (newTaskTitle.length !== 0) {
      onAddTask(newTaskTitle);
    }

    setIsAddingTask(false);
    setNewTaskTitle('');
  };

  return (
    <View>
      {isAddingTask ? (
        <View
          sx={{
            height: HEIGHT,
            flexDirection: 'row',
            backgroundColor: 'card',
            borderTopLeftRadius: RADII,
            borderTopRightRadius: RADII,
          }}
        >
          <TextInput
            sx={{
              flex: 1,
              paddingLeft: 3,
              color: 'text',
              fontSize: 4,
            }}
            value={newTaskTitle}
            onChangeText={handleChangeText}
            autoFocus
            {...testId(TestId.NewTaskInput)}
          />
          <TouchableOpacity
            onPress={handleAddTaskPress}
            {...testId(TestId.AddTaskButton)}
          >
            <View
              sx={{
                width: HEIGHT,
                height: HEIGHT,
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Icon name="check" size={24} sx={{ color: 'primary' }} />
            </View>
          </TouchableOpacity>
        </View>
      ) : (
        <TouchableOpacity
          onPress={handleNewTaskPress}
          {...testId(TestId.NewTaskButton)}
        >
          <View
            sx={{
              height: HEIGHT,
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Text variant="subtleHeading">New Task</Text>
          </View>
        </TouchableOpacity>
      )}
    </View>
  );
};
