import { theme } from '../../theme/theme';

import { StyleSheet } from 'react-native';
import { ThemeTokens } from '../../theme/types';

type StyleFn = (theme: ThemeTokens) => ReturnType<typeof StyleSheet.create>;

export const useTheme = (styleFn?: StyleFn) => ({
  ...theme,
  styles: styleFn?.(theme),
});
