import { useMutation, useQueryClient } from '@tanstack/react-query';
import { nanoid } from 'nanoid';
import Api from '../../../api';
import { Task } from '../../../types';

export const useAddTask = () => {
  const queryClient = useQueryClient();
  const { mutateAsync } = useMutation(Api.addTask, {
    onMutate: async (newTodo) => {
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries(['tasks']);

      // Snapshot the previous value
      const previousTodos = queryClient.getQueryData<Task[]>(['inboxTasks']);

      // Optimistically update to the new value
      queryClient.setQueryData<Task[]>(['inboxTasks'], (old) =>
        old ? [...old, { id: nanoid(), title: newTodo }] : []
      );

      // Return a context object with the snapshotted value
      return { previousTodos };
    },
    // If the mutation fails, use the context returned from onMutate to roll back
    onError: (err, newTodo, context) => {
      queryClient.setQueryData<Task[]>(['todos'], context?.previousTodos);
    },
    // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries(['todos']);
    },
  });

  return mutateAsync;
};
