import { useCallback, useEffect, useState } from 'react';
import {
  useSharedValue,
  useAnimatedReaction,
  runOnJS,
} from 'react-native-reanimated';

const TIMER_INTEVAL = 1000;

export type TimerState = {
  endTime: number | null;
};

export type UseTimerProps = {
  onStart?: (state: TimerState) => void;
  onTick?: () => void;
  onEnd?: () => void;
};
export const useTimer = ({ onStart, onTick, onEnd }: UseTimerProps = {}) => {
  const [duration, setDuration] = useState(0);
  const [isRunning, setIsRunning] = useState(false);
  const [endTime, setEndTime] = useState<number>(0);

  useEffect(() => {
    if (!isRunning) return;

    const startTime = Date.now();
    let timeoutId: null | ReturnType<typeof setTimeout> = null;
    let nextTime = startTime + TIMER_INTEVAL;

    const tick = () => {
      const currentTime = Date.now();

      if (currentTime >= endTime) {
        setIsRunning(false);
        setDuration(0);
        onEnd?.();
        return;
      }

      const drift = currentTime - nextTime;
      const msUntilNextTick = Math.max(0, TIMER_INTEVAL - drift);
      nextTime = nextTime + TIMER_INTEVAL;

      setDuration((prevDuration) => prevDuration - 1);
      onTick?.();

      timeoutId = setTimeout(tick, msUntilNextTick);
    };

    timeoutId = setTimeout(tick, TIMER_INTEVAL);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [isRunning, endTime, onTick, onEnd]);

  const reset = () => {
    setIsRunning(false);
    setDuration(0);
    setEndTime(0);
  };

  const start = () => {
    if (duration) {
      const currentTime = Date.now();
      const newEndTime = currentTime + duration * 1000;
      setEndTime(newEndTime);
      setIsRunning(true);
      onStart?.({ endTime: newEndTime });
    }
  };

  const pause = () => {
    setIsRunning(false);
  };

  const setDurationInSeconds = useCallback((duration: number) => {
    setDuration(duration);
  }, []);

  return {
    durationInSeconds: duration,
    endTime,
    setDurationInSeconds,
    isRunning,
    start,
    pause,
    reset,
  };
};
