import React, { PropsWithChildren } from 'react';
import { Platform } from 'react-native';
import { PanGestureHandler } from 'react-native-gesture-handler';
import Animated, {
  runOnJS,
  SharedValue,
  useAnimatedGestureHandler,
} from 'react-native-reanimated';
import { canvas2Polar } from 'react-native-redash';
import { testId, TestId } from '../../../constants/testIds';
import * as Haptics from 'expo-haptics';

const INITIAL_ANGLE_OFFSET = Math.PI / 2;
const HAPTIC_TRIGGER_ANGLE = 6;

type ClockGestureProps = PropsWithChildren<{
  size: number;
  angle: SharedValue<number>;
  enabled?: boolean;
  snapToAngle?: number;
  onSnap?: () => void;
}>;
export const ClockGesture = ({
  size,
  angle,
  snapToAngle,
  enabled,
  children,
  onSnap,
}: ClockGestureProps) => {
  const halfSize = size / 2;

  const onGestureEvent = useAnimatedGestureHandler({
    onActive: ({ x, y }) => {
      const polar = canvas2Polar({ x, y }, { x: halfSize, y: halfSize });
      let nomaliszedTheta = INITIAL_ANGLE_OFFSET - polar.theta;

      if (nomaliszedTheta < 0) {
        nomaliszedTheta += Math.PI * 2;
      }

      const previousAngle = angle.value;
      let nextAngle = (nomaliszedTheta * 180) / Math.PI;

      if (snapToAngle) {
        nextAngle = Math.round(nextAngle / snapToAngle) * snapToAngle;
      }

      if (Platform.OS === 'ios') {
        const previousSlice = Math.floor(previousAngle / HAPTIC_TRIGGER_ANGLE);
        const nextSlice = Math.floor(nextAngle / HAPTIC_TRIGGER_ANGLE);

        if (previousSlice !== nextSlice) {
          runOnJS(onSnap)();
        }
      }

      angle.value = nextAngle;
    },
  });
  return (
    <PanGestureHandler
      onGestureEvent={onGestureEvent}
      minDist={0}
      enabled={enabled}
    >
      <Animated.View {...testId(TestId.TimerClockFace)}>
        {children}
      </Animated.View>
    </PanGestureHandler>
  );
};
