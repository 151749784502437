import React from 'react';
import { useSx, View } from 'dripsy';
import { FlatList } from 'react-native';
import { Task } from '../../../types';
import { TaskListItem } from './TaskListItem';

type TaskListProps = {
  tasks: Task[];
  onTaskPress: (id: string) => void;
};

export const TaskList = ({ tasks, onTaskPress }: TaskListProps) => {
  const sx = useSx();

  return (
    <View sx={{ flex: 1 }}>
      <FlatList
        data={tasks}
        renderItem={({ item }) => (
          <TaskListItem
            id={item.id}
            title={item.title}
            onPress={() => onTaskPress(item.id)}
          />
        )}
        keyExtractor={(item) => item.id}
        ItemSeparatorComponent={ItemSeparatorComponent}
        style={sx({ paddingTop: '3', paddingX: '3' })}
        contentInsetAdjustmentBehavior="automatic"
      />
    </View>
  );
};

const ItemSeparatorComponent = () => <View sx={{ height: '1' }} />;
