import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { HabitsStackParamList, RootTabScreenProps } from '../../../types';
import { HabitsHomeScreen } from './HabitsHomeScreen';

const HabitsStack = createNativeStackNavigator<HabitsStackParamList>();

export default function TasksRootScreen({}: RootTabScreenProps<'TasksRoot'>) {
  return (
    <HabitsStack.Navigator
      screenOptions={{
        headerTintColor: 'white',
        headerLargeTitle: true,
      }}
    >
      <HabitsStack.Screen
        name="HabitsHome"
        component={HabitsHomeScreen}
        options={{ title: 'Habits' }}
      />
    </HabitsStack.Navigator>
  );
}
