import React from 'react';
import { View } from 'dripsy';
import { TasksStackScreenProps } from '../../../types';
import { NewTaskSection } from '../components/NewTaskSection';
import { TasksInboxList } from '../components/TasksInboxList';

export default function TasksInboxScreen({}: TasksStackScreenProps<'TasksInbox'>) {
  return (
    <View sx={{ flex: 1 }}>
      <TasksInboxList />
      <NewTaskSection />
    </View>
  );
}
