import { makeTheme } from 'dripsy';

const lightColors = {
  background: '#fff',
  text: '#000',
  primary: '#07c',
  card: '#fff',
  mutedText: '#888',
};

export const lightTheme = makeTheme({
  types: {
    reactNativeTypesOnly: true,
  },
  colors: lightColors,
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64],

  space: [0, 4, 8, 16, 32, 64, 128, 256],
  sizes: [0, 4, 8, 16, 32, 64, 128, 256],
  radii: [0, 2, 4, 8, 16, 32, 64, 128],
  text: {
    subtleHeading: {
      fontSize: 20,
      color: 'mutedText',
    },
    h3: {
      fontSize: 20,
      color: 'text',
    },
  },
});

export const darkTheme: typeof lightTheme = {
  ...lightTheme,
  types: {
    reactNativeTypesOnly: true,
  },
  colors: {
    background: '#000',
    text: '#fff',
    primary: '#0cf',
    card: '#111',
    mutedText: '#888',
  },
};

type MyTheme = typeof lightTheme;

declare module 'dripsy' {
  interface DripsyCustomTheme extends MyTheme {}
}
