import React, { useMemo } from 'react';
import { TestId } from '../../../constants/testIds';
import { InputButton, InputButtonProps } from './InputButton';

const DATE_FORMAT = new Intl.DateTimeFormat('en', {
  hour: 'numeric',
  minute: 'numeric',
  hour12: false,
});

type EndTimeInputButtonProps = {
  endTime: number;
} & Pick<InputButtonProps, 'disabled' | 'onChangeText'>;
export const EndTimeInputButton = ({
  endTime,
  disabled,
  onChangeText,
}: EndTimeInputButtonProps) => {
  const endTimeText = useMemo(
    () => DATE_FORMAT.format(endTime === 0 ? Date.now() : endTime),
    [endTime]
  );

  return (
    <InputButton
      label="Ends At"
      value={endTimeText}
      disabled={disabled}
      keyboardType="number-pad"
      onChangeText={onChangeText}
      testID={TestId.TimerEndTimeDisplay}
    />
  );
};
