import React from 'react';
import { TestId } from '../../../constants/testIds';
import { InputButton, InputButtonProps } from './InputButton';

type DurationInputButton = {
  duration: number;
} & Pick<InputButtonProps, 'disabled' | 'onChangeText'>;
export const DurationInputButton = ({
  duration,
  disabled,
  onChangeText,
}: DurationInputButton) => {
  const minutes = Math.floor(duration / 60);
  const seconds = duration % 60;
  const durationText = `${minutes.toString().padStart(2, '0')}:${seconds
    .toString()
    .padStart(2, '0')}`;

  return (
    <InputButton
      label="Duration"
      value={durationText}
      disabled={disabled}
      keyboardType="number-pad"
      onChangeText={onChangeText}
      testID={TestId.TimerDurationDisplay}
    />
  );
};
